import React, { forwardRef, useEffect } from "react";
import { useHistory } from "react-router";
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';
import "@material-ui/core";
import YellowPagesNavigation from "./leftnav";
import Axios from "axios";
import PostReply from "./../components/dashboard/PostReply";
import PropTypes from "prop-types";
import { withStyles  } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import clsx from "clsx";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardMedia from "@material-ui/core/CardMedia";
import CardContent from "@material-ui/core/CardContent";
import CardActions from "@material-ui/core/CardActions";
import Collapse from "@material-ui/core/Collapse";
import Avatar from "@material-ui/core/Avatar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import { red } from "@material-ui/core/colors";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ShareIcon from "@material-ui/icons/Share";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Box from "@material-ui/core/Box";
import EventQuickLinks from "./homeapps/EventQuickLinks";
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import RestoreIcon from '@material-ui/icons/Restore';
import MusicNoteIcon from '@material-ui/icons/MusicNote';
import SportsCricketIcon from '@material-ui/icons/SportsCricket';
import GroupWorkIcon from '@material-ui/icons/GroupWork';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import NaturePeopleIcon from '@material-ui/icons/NaturePeople';
import PeopleIcon from '@material-ui/icons/People';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import LocalBarIcon from '@material-ui/icons/LocalBar';
import ListIcon from '@material-ui/icons/List';
import MovieIcon from '@material-ui/icons/Movie';
import Utils from './utils/homeutils';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import * as _ from 'lodash';


function a11yProps(index: any) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
function dateFormat2(d :any){
		let monthShortNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
  "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
];
    let t = new Date(d);
    return monthShortNames[t.getMonth()]+ ' ' + t.getDate();
  }
const useStyles = (theme:any) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    outline: "none",
  },
  padding: {
    padding: theme.spacing(0),
  },
  indicator: {
     backgroundColor: "#fff"
    //backgroundColor: '#fff#823587',
  },
  indicatorColor: {
    backgroundColor: "#82338d",
  },
  "&.Mui-selected": {
    outline: "none",
  },
  rootCard: {
    maxWidth: 345,
  },
  media: {
    height: 0,
    paddingTop: "56.25%", // 16:9
  },
  expand: {
    transform: "rotate(0deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
  },
  avatar: {
    backgroundColor: red[500],
  },
  subheader: {
    //backgroundColor: '#FF9933',
    backgroundColor: "#823587",
    minHeight: 24,
    height: 42,
    color: "#fff",
  },
});


class Events extends React.Component<{   
componentName: any,
allBusinessItems:any[],
match:any,
classes:any,
history:any
},{
	value:any,
	events:any[],
	categories:any[],
	loading:boolean
}>{
    constructor(props: any) {
        super(props);
		this.state={
			value:"All",
			events:[],
			categories:[],
			loading:true
		}
    }
	async componentDidUpdate(prevProps:any, _prevState:any) {
		if(prevProps.match.params.id!=this.props.match.params.id){
			this.setState({value:this.props.match.params.id,loading:false});
		}
	}
   async componentDidMount() {
		let categories=await Utils.GetEventCategoryItems();
		let uniqCategories = _.sortBy(categories, (e) => {
      return e._source.id
    })
		this.props.componentName("Events");	
		let eventCatID = this.props.match.params.id;
		this.setState({categories:uniqCategories, value:eventCatID,loading:false});		
    } 
	handleChange=(event:any, newValue:any)=>{
		//this.setState({value:newValue});private GoToEntity = (obj: any) => {
		this.props.history.push("/events/" + newValue.toLowerCase() + "");
	}
	
    render() {
	 const {allBusinessItems,classes,history }=this.props;	 
     let {value,categories,loading}=this.state;
        return (
           <div className="d-flex" id="jh-wrapper">
      <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
        <div className="row">
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <a href="#">Home</a>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                Events
              </li>
            </ol>
          </nav>
        </div>
        <div className="jh-content-body">
          <div className="col-md-12 jh-events-title">
            <h2 className="jh-title-1">Events</h2>
          </div>
          <div className="col-11 col-lg-11 col-md-11 col-sm-12">
            <div className="jh-events">
              <div className="jh-events-body">
                <div className={classes.root}>                 
                   <AppBar position="static" color="default">
										<Tabs
										  value={value}
										  onChange={this.handleChange}
										  variant="scrollable"
										  scrollButtons="auto"
										  className={classes.indicator}
										  TabIndicatorProps={{ className: classes.indicatorColor }}
										  aria-label="scrollable force tabs example"
										>
										{categories.map((category: any) => (
                          <Tab
                            label={category._source.title} value={category._source.title.toLowerCase()}
                            {...a11yProps(0)}
                            style={{ minWidth: 75 }}
                          />
                        ))}
										  									  
										</Tabs>
					  </AppBar>
					  
					  <div className="row eventscontentwrapper">					  
						  {value.toLowerCase()==="all" && categories.map((category: any) => (
							  allBusinessItems.filter((e:any)=>e._source.recordtype==="Event" && e._source.category.toLowerCase()===category._source.title.toLowerCase()).length>0 &&
								(<div className="col-lg-4 col-md-6 col-sm-6 col-12">
									<EventQuickLinks
									  nodataHeight="160px"
									  history={history}
									  loading={loading}
									  items={allBusinessItems.filter((e:any)=>e._source.recordtype==="Event" && e._source.category.toLowerCase()===category._source.title.toLowerCase())}
									  widgetIcon="fa fa-yelp"
									  widgettitle={category._source.title}
									  widgetcolor="widget-color-5"
									></EventQuickLinks>
								</div>)
						  ))}
						  {value.toLowerCase()!=="all" && allBusinessItems.filter((e:any)=>e._source.recordtype==="Event" && e._source.category.toLowerCase()===value.toLowerCase()).map(function (item) {
											return (
											<div className="card-columns jh-card-columns">
												<div className="card">
													<div className="card-body">
														<h5 className="card-title">
														<Link to={`/entity/${item._source.businessname.replace(/[^a-zA-Z0-9]/g, '') }/ ${item._source.id}`}>{item._source.businessname}</Link>												
														</h5> 
														<p><i className="fa fa-calendar"/> {dateFormat2(item._source.eventstarttime)} - {dateFormat2(item._source.eventendtime)}</p>
														<p className="jh-event-p-addr"><i className="fa fa-map-marker"/> {item._source.address}</p>
													</div>
												</div>
											</div>	
											);
										})}
					  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
    </div>
           
        )

    }
}



export default withStyles(useStyles)(Events);

