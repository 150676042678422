import React from 'react';
import ReactDOM from 'react-dom';
import logo from './logo.svg';
import barcode1 from './img/barcode1.png';
import barcode2 from './img/barcode2.png';
import adv3nodata from './img/adv3nodata.gif';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import Autocomplete from '@material-ui/lab/Autocomplete';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import Utils from './components/utils/homeutils';
import './App.css';
//import './css/jaihostyles.css';
//import './css/normalize.css';
import 'font-awesome/css/font-awesome.min.css';
import { BrowserRouter as Router, Route, Link, useHistory } from 'react-router-dom';
//import './css/customstyle.css';
import Home from './components/home';
import About from './components/about';
import Trends from './components/trends';
import AddBusiness from './components/addbusiness';
import Advertisements from './components/advertisements';
import Events from './components/events';
import Employment from './components/employment';
import JobRegistration from './components/jobregistration';
import Contact from './components/contact';
import Awards from './components/awards';
import UsefulLinks from './components/usefullinks';
import UsefulLinksByCat from './components/usefullinksbycat';
import Login from './components/login';
import UserRegistration from './components/auth/registration';
import ChangePwd from './components/auth/changepwd';
import ForgotPassword from './components/auth/forgotpwd';
import Hospitals from './components/yellopages/healthcare';
import Restaurents from './components/yellopages/restaurents';
import AdminPage from './components/admin/AdminPage';
import DiscussionPage from './components/dashboard/DiscussionPage';
import CreateTopic from './components/dashboard/CreateTopic';
import PostReply from './components/dashboard/PostReply';
import DisclaimerPage from './components/dashboard/DisclaimerPage';
import CreateTopicDisclaimerPage from './components/dashboard/CreateTopicDisclaimerPage';
import Polling from './components/polling/Polling';
import 'bootstrap/dist/css/bootstrap.min.css';
import './css/normalize.css';
import './css/color1.css';
import './css/jaihostyles.css';
import $ from 'jquery';
import 'bootstrap/dist/js/bootstrap.min.js';
import BannerImg1 from './img/banner1.gif';
import Logo from './img/jaihologo.png';
// from './img/footer.jpg';
import JaihoFooter from './components/footer';
import Entity from './components/entity';
import EmploymentEntity from './components/employmententity';
import AllServices from './components/tiles';
import YellowPagesNavigation from './components/leftnav';
import MainCategories from './components/categories';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from "@material-ui/core/styles";
import * as _ from 'lodash';
import { Typography } from '@material-ui/core';
import { AnyCnameRecord } from 'dns';
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
//import { title } from 'process';

//import { AnyAaaaRecord } from 'dns';

//const history=new useHistory();
import Search from './components/search';
import Franchise from './components/franchise';
import { runInThisContext } from 'vm';
import EventServices from './components/eventServices';
import axios from 'axios';
import SR from 'react-speech-recognition';
import { red } from '@material-ui/core/colors';
var moment = require('moment');
const options = {
  autoStart: false,
  continuous: false,
}

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

class App extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      elkTopCitySearchResults: [],
      elkCities: [],
      searchCityKey: "",
      citykey: "",
      IsCitySearchSelected: false,
      IsUserSignin: "",
      UserName: "",
      CompName: "",
      isGeoAvailable: "",
      open: true,
      vertical: "top",
      horizontal: "center",
      setOpen: true,
      selectedCity: "",
      City: "",
      Cities: [],
      States: [],
      Country: "0",
      selectedCountry: "",
      IsCitySelected: false,
      elkSearchResults: [],
      elkTopSearchResults: [],
      elkAllResults: [],
      Countries: [],
      searchLoading: false,
      searchKey: "",
      allSearchKey: "",
      countryLoading: true,
      isCityAvailable: false,
      CityID: "",
      StateID: "",
      Temperature: "",
      Claimate: "",
      ClaimateCode: "",
      IsFranchiseSelected: false,
      IsMobilelected: false,
      franchiseopen: false,
      mobileappopen: false,
      AllBusinessForCity: [],
      AllBusinessRanksForCity: [],
      adv3gif: "",
      adv3Cat: "",
      adv3CatID: "",
      isLoading: false,
      isAdv3Loading: true,
      mainDataLoading: true,
      franchiseoOwnerLoading: true,
      weatherCityID: "",
      Categories: [],
      SubCategories: [],
      AdvertisementTypes: [],
      franchiseFields: {},
      franchiseErrors: {},
      franchisesnackbaropen: false,
      successMsg: "",
      subscribeemail: "",
      subscribeerror: "",
      searchKeyValidation: false,
      toggleActive: false,
      isVoiceRecording: false,
      userLat: "",
      userLong: "",
      isLocationLoading: true,
      franchiseOwner: []
    }
    this.IsUserLoggedIn = this.IsUserLoggedIn.bind(this);
    this.ChangePasswordCallback = this.ChangePasswordCallback.bind(this);
    this.IsComponetLaoded = this.IsComponetLaoded.bind(this);
    this.SnackbarCallback = this.SnackbarCallback.bind(this);
    this.ClearTopResults = this.ClearTopResults.bind(this);
    this.franchiseHandleChange = this.franchiseHandleChange.bind(this);
    this.submitFranchiseForm = this.submitFranchiseForm.bind(this);
    this.subscribeHandleChange = this.subscribeHandleChange.bind(this);
    this.subscribeClick = this.subscribeClick.bind(this);
  }



  ScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
  ScrollToBottom = () => {
    window.scrollTo({ top: 1000, behavior: 'smooth' });
  }
  subscribeHandleChange = (e) => {
    this.setState({
      subscribeemail: e.target.value
    });
  }
  SubscriptionValidateForm = () => {
    let field = this.state.subscribeemail;
    let error = this.state.subscribeerror;
    let formIsValid = true;
    if (!field) {
      formIsValid = false;
      this.setState({ subscribeerror: "*Please enter your email." });
    }
    let regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regexEmail.test(field)) {
      formIsValid = false;
      this.setState({ subscribeerror: "*Please enter valid email." });
    }
    return formIsValid;
  }
  subscribeClick = (e) => {
    let mainSate = this;
    e.preventDefault();
    if (this.SubscriptionValidateForm()) {
      axios.post("https://api.jaiho.com/Subscribe", {
        email: this.state.subscribeemail
      }).then(function (response) {
        if (response.data.statusCode === 200) {
          //mainSate.setState({franchisesnackbaropen:true});	
        } else {
          //mainScope.setState({ RegistrationStatus: "Failed" });
        }
      })
        .catch(function (error) {
          console.log(error);
        });

      this.setState({ subscribeemail: "", subscribeerror: "" });
      mainSate.setState({ franchisesnackbaropen: true, successMsg: "Your request han been successfully submitted!" });
    }
  }
  // Franchise handles
  franchiseHandleChange = (e) => {
    let fields = this.state.franchiseFields;
    fields[e.target.name] = e.target.value;
    this.setState({
      fields
    });
  }
  submitFranchiseForm = (e) => {
    let mainSate = this;
    e.preventDefault();
    if (this.FranchiseValidateForm()) {
      axios.post("https://api.jaiho.com/FranchiseEmail", {
        Country: this.state.franchiseFields.country,
        State: this.state.franchiseFields.userstate,
        City: this.state.franchiseFields.city,
        Name: this.state.franchiseFields.username,
        Email: this.state.franchiseFields.email,
        AreaCode: this.state.franchiseFields.code,
        Phone: this.state.franchiseFields.phone,
        Message: this.state.franchiseFields.comments
      }).then(function (response) {
        if (response.data.statusCode === 200) {
          //mainSate.setState({franchisesnackbaropen:true});	
        } else {
          //mainScope.setState({ RegistrationStatus: "Failed" });
        }
      })
        .catch(function (error) {
          console.log(error);
        });

      let fields = this.state.franchiseFields;
      fields["country"] = "";
      fields["userstate"] = "";
      fields["city"] = "";
      fields["username"] = "";
      fields["email"] = "";
      fields["code"] = "";
      fields["phone"] = "";
      fields["comments"] = "";
      this.setState({
        Fields: fields, IsFranchiseSelected: false, franchiseopen: false,
        franchisesnackbaropen: true, successMsg: "Your request han been successfully submitted!"
      });
    }
  }
  FranchiseValidateForm = () => {
    let fields = this.state.franchiseFields;
    let errors = this.state.franchiseErrors;
    errors["country"] = "";
    errors["userstate"] = "";
    errors["city"] = "";
    errors["username"] = "";
    errors["email"] = "";
    errors["code"] = "";
    errors["phone"] = "";
    let formIsValid = true;
    if (!fields.country) {
      formIsValid = false;
      errors["country"] = "*Please enter country.";
    }
    if (!fields.userstate) {
      formIsValid = false;
      errors["userstate"] = "*Please enter state.";
    }
    if (!fields.city) {
      formIsValid = false;
      errors["city"] = "*Please enter city.";
    }
    if (!fields.username) {
      formIsValid = false;
      errors["username"] = "*Please enter your name.";
    }
    if (!fields.email) {
      formIsValid = false;
      errors["email"] = "*Please enter your email.";
    }
    let regexEmail = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
    if (!regexEmail.test(fields.email)) {
      formIsValid = false;
      errors["email"] = "*Please enter valid email.";
    }
    if (!fields.phone) {
      formIsValid = false;
      errors["phone"] = "*Please enter your phone number.";
    }
    let regexPhone = /^[0-9]*$/;
    if (!regexPhone.test(fields.phone)) {
      formIsValid = false;
      errors["phone"] = "*Please enter valid phone number.";
    }
    this.setState({
      franchiseErrors: errors
    });
    return formIsValid;
  }
  FranchiseClick = () => {
    this.setState({ IsFranchiseSelected: true, franchiseopen: true });
  }
  FranchiseClose = () => {
    let fields = this.state.franchiseFields;
    let errors = this.state.franchiseErrors;
    fields["country"] = "";
    fields["userstate"] = "";
    fields["city"] = "";
    fields["username"] = "";
    fields["email"] = "";
    fields["code"] = "";
    fields["phone"] = "";
    fields["comments"] = "";
    errors["country"] = "";
    errors["userstate"] = "";
    errors["city"] = "";
    errors["username"] = "";
    errors["email"] = "";
    errors["code"] = "";
    errors["phone"] = "";
    this.setState({ franchiseFields: fields, franchiseErrors: errors, IsFranchiseSelected: false, franchiseopen: false });
  }
  handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({ franchisesnackbaropen: false });
    //setOpen(false);
  };
  MobileAppClick = () => {
    this.setState({ IsMobilelected: true, mobileappopen: true });
  }
  MobileAppClose = () => {
    this.setState({ IsMobilelected: false, mobileappopen: false });
  }
  handleClose = () => {
    this.setState({ open: false });
  }
  handleContinue = async (obj, objID, objStateID, objCountryID, objCountryName, objWeatherID) => {
    //this.setState({AllBusinessForCity:[],AllBusinessRanksForCity:[]});
    localStorage.setItem("UserCountry", objCountryID);
    localStorage.setItem("UserCountryName", this.state.selectedCountry);
    localStorage.setItem("UserCity", obj);
    localStorage.setItem("UserCityID", objID);
    localStorage.setItem("UserCityWeatherID", objWeatherID);
    localStorage.setItem("UserStateID", objStateID);
    this.setState({ open: false, isCityAvailable: true, City: obj, CityID: objID, StateID: objStateID, Country: objCountryID, selectedCountry: objCountryName, mainDataLoading: true, IsCitySearchSelected: false, searchCityKey: "", elkTopCitySearchResults: [], citykey: "", elkCities: [] });
    if (objWeatherID != null && objWeatherID != "") {
      var weatherURL = "https://openweathermap.org/city/" + objWeatherID;
      this.setState({ WeatherSiteURL: weatherURL });
    } else {
      this.setState({ WeatherSiteURL: "https://openweathermap.org" });
    }

    try {
      let weatherInfo = null;
      weatherInfo = await Utils.GetWeatherInfo(obj);
      if (weatherInfo != null) {
        let weatherTemp = Number(weatherInfo.main.temp).toFixed(1);
        this.setState({
          Temperature: weatherTemp, Claimate: weatherInfo.weather[0].main,
          ClaimateCode: `http://openweathermap.org/img/wn/${weatherInfo.weather[0].icon}@2x.png`
        });
      }
    }
    catch (e) {
    }
    console.log("City changed by User");
    // Get all businessentityid for city
    let allBusinessEntities = await Utils.GetAllBuinessForCity(this.state.City);
    // Get all advertisement for city
    let allAdtisements = await Utils.GetBusinessRanking(this.state.CityID);
    this.setState({ AllBusinessForCity: allBusinessEntities, AllBusinessRanksForCity: allAdtisements, mainDataLoading: false });
    let adv3 = [];
    let adv3gifname = [];
    let adv3now = moment().format("YYYY-MM-DD");
    adv3 = allAdtisements.filter(function (elkItem) {
      //return elkItem._source.advtypeid === 3 && new Date() >= new Date(elkItem._source.startdate) && new Date() <= new Date(elkItem._source.tilldate)
      return elkItem._source.advtypeid === 3 && moment(adv3now, "YYYY-MM-DD").diff(moment(elkItem._source.startdate, "YYYY-MM-DD")) >= 0 && moment(elkItem._source.tilldate, "YYYY-MM-DD").diff(moment(adv3now, "YYYY-MM-DD")) >= 0
    });
    if (adv3.length > 0) {
      console.log("entered adv3");
      adv3gifname = allBusinessEntities.filter(function (elkItem) {
        return elkItem._source.id === adv3[0]._source.businessentityid
      });
      console.log(adv3gifname);
      if (adv3gifname.length > 0 && adv3gifname[0]._source.gifname != "") {
        this.setState({ adv3gif: adv3gifname[0]._source.gifname, isAdv3Loading: false, adv3Cat: adv3gifname[0]._source.businessname, adv3CatID: adv3gifname[0]._source.id });
      } else {
        this.setState({ adv3gif: "NA", isAdv3Loading: false });
      }
    } else {
      this.setState({ adv3gif: "NA", isAdv3Loading: false });
    }
    let franchiseownerdetails = await Utils.GetFranchiseOwnersForCity(this.state.CityID);
    this.setState({ franchiseOwner: franchiseownerdetails });
  }
  // Callback functions  
  IsComponetLaoded = (obj) => {
    this.setState({ CompName: obj });
    this.ScrollToTop();
  }
  SnackbarCallback = (obj, msg) => {
    this.setState({ franchisesnackbaropen: obj, successMsg: msg });
  }
  IsUserLoggedIn = (obj) => {
    if (obj == "F") {
      this.setState({ IsUserSignin: obj });
    } else {
      this.setState({ IsUserSignin: "S" });
      localStorage.setItem("AuthFlag", "S");
      localStorage.setItem("UN", obj.name);
      this.setState({ UserName: obj.name });
    }
  }
  ChangePasswordCallback = (obj) => {
    if (obj == "Y") {
      this.setState({ IsUserSignin: "" });
      this.setState({ UserName: "" });
      //this.props.history.push("/login"); 
      localStorage.clear();
    }
  }
  // Callback functions - End
  SignOut = () => {
    this.setState({ IsUserSignin: "" });
    this.setState({ UserName: "" });
    //this.props.history.push("/");
    localStorage.clear();
  }
  ChangePassword = () => {
    this.props.history.push("/auth/changepwd");
  }
  EnableCitySearch = async () => {
    let cities = await Utils.GetAllCities();
    let uniqCities = _.sortBy(cities, (e) => {
      return e._source.title
    })
    let topCities = uniqCities.filter(e => e._source.countryid === Number(this.state.Country) && e._source.ismajor === true);
    this.setState({ Cities: uniqCities, IsCitySearchSelected: true, elkTopCitySearchResults: topCities });
  }
  ClearCitySearch = async () => {

    this.setState({ IsCitySearchSelected: false, searchCityKey: "", elkTopCitySearchResults: [] });
  }
  ChangeCity = async () => {
    let cities = await Utils.GetCityItems(Number(this.state.Country));
    let uniqCities = _.sortBy(cities, (e) => {
      return e._source.title
    })
    this.setState({ Cities: uniqCities, IsCitySelected: true, open: true });
  }
  GetCitySerachResultsWrapper = async (e) => {
    let keyVal = e.target.value;
    this.setState({ citykey: e.target.value });
    if(e.target.value==="")
    {
      let metroCities=this.state.Cities.filter(e => e._source.ismajor===true);
      this.setState({ elkCities: metroCities });
      return;
    }
    let cities = this.state.Cities.filter(e => e._source.title.toLowerCase().startsWith(keyVal));
    this.setState({ elkCities: cities });
  }
  GetCitySerachResults = async (e) => {
    let keyVal = e.target.value;
    this.setState({ searchCityKey: e.target.value });
    if(e.target.value==="")
    {
      let metroCities=this.state.Cities.filter(e => e._source.ismajor===true && e._source.countryid===Number(this.state.Country));
      this.setState({ elkTopCitySearchResults: metroCities });
      return;
    }
    let cities = this.state.Cities.filter(e => e._source.title.toLowerCase().startsWith(keyVal));
    this.setState({ elkTopCitySearchResults: cities });
  }
  GetELKSerachResults = async (e) => {
    this.setState({ searchLoading: true, searchKey: e.target.value });
    let elkResults = [];
    if (e.target.value.length < 4) {
      this.setState({ elkSearchResults: [] });
    } else {
      elkResults = await Utils.GetSearchResultItems(this.state.City, e.target.value + "*", 10);
      let uinqResults = _.uniqBy(elkResults, '_id');
      let topResults = elkResults.slice(0, 10);
      this.setState({ elkSearchResults: elkResults, elkTopSearchResults: topResults });
      this.setState({ searchLoading: false });
    }
  }
  SearchPopupClose = () => {
    //this.GetVoiceSearchResults(this.state.searchKey);

    this.GetVoiceSearchResults(this.props.transcript);
  }
  GetVoiceSearchResults = async (obj) => {
    this.setState({ searchKey: obj, isVoiceRecording: false, searchLoading: true, elkSearchResults: [] });
    this.setState({ searchKey: obj, searchLoading: true, elkSearchResults: [] });
    let elkResults = [];
    if (obj.length > 3) {
      //this.setState({ elkSearchResults: [] });
      elkResults = await Utils.GetSearchResultItems(this.state.City, obj + "*", 10);
      let uinqResults = _.uniqBy(elkResults, '_id');
      let topResults = elkResults.slice(0, 10);
      this.setState({ elkSearchResults: elkResults, elkTopSearchResults: topResults });
      this.setState({ searchLoading: false });
    } else {
      this.setState({ elkSearchResults: [] });
    }
  }

  GetCities = async (obj, obj1) => {
    this.setState({ Country: obj });
    if (obj != "0") {
      this.setState({ selectedCountry: obj1.selectedOptions[0].text });
    }
    let cities = await Utils.GetCityItems(Number(obj));
    let uniqCities = _.sortBy(cities, (e) => {
      return e._source.title
    })
    this.setState({ Cities: uniqCities, countryLoading: false });
    //localStorage.setItem("lsCities", JSON.stringify(uniqCities));
  }
  GoToEntity = () => {
    this.setState({ elkTopSearchResults: [], searchKey: '' });
  }
  ClearTopResults = () => {
    if (this.state.searchKey.length > 2) {
      this.setState({ elkTopSearchResults: [] });
      this.setState({ elkAllResults: this.state.elkSearchResults, allSearchKey: this.state.searchKey });
    } else {
      this.setState({ searchKeyValidation: true });
    }
  }
  sidebarToggle = () => {
    $("#jh-wrapper").toggleClass("toggled");
  }
  async componentDidUpdate(prevProps, _prevState) {
    if (_prevState.userCity !== this.state.userCity) {
      console.log("Componet did update");
    }
  }

  getLatLon = async (position) => {
    let latitude = position.coords.latitude;
    let longitude = position.coords.longitude;
    console.log("Latitude is " + latitude);
    console.log("Longitude is " + longitude);
    //this.state({userLat:latitude,userLong:longitude});
    let userCityInformation = await Utils.GetUserCityByCoords(latitude, longitude);
    let cityname = "";
    try {
      cityname = userCityInformation.address.city;
    } catch (e) {
    }
    if (cityname != "") {
      let objCity = await Utils.GetCityInfoFromJHDB(cityname);
      console.log(objCity[0]);
      let objCityFromDB = objCity[0];
      localStorage.setItem("UserCountry", objCityFromDB._source.countryid);
      localStorage.setItem("UserCountryName", objCityFromDB._source.countryname);
      localStorage.setItem("UserCity", objCityFromDB._source.title);
      localStorage.setItem("UserCityID", objCityFromDB._source.id);
      localStorage.setItem("UserStateID", objCityFromDB._source.stateid);
    }
    this.setState({ isLocationLoading: false });
  }
  getPosition = () => {
    return new Promise((resolve, reject) => {
      navigator.geolocation.getCurrentPosition((position) => {
        resolve(position.coords);
      }, (err) => {
        reject(err);
      });
    });
  }

  async componentDidMount() {
    let isLSAvailable = localStorage.getItem("JHLocalStorageV11252021");
    if (isLSAvailable == null || isLSAvailable == "") {
      // clear JaiHo local storage
      localStorage.removeItem("UserCity");
      localStorage.removeItem("UserCountry");
      localStorage.removeItem("UserCityID");
      localStorage.removeItem("UserStateID");
      localStorage.removeItem("IPAddress");
      localStorage.removeItem("PID");
      localStorage.removeItem("UserCityWeatherID");
      localStorage.setItem('JHLocalStorageV11252021', 'JH');
    }
    let that = this;
    $(document).mouseup(function (e) {
      let container = $(".header-inner");
      if (!container.is(e.target) && container.has(e.target).length === 0) {
        //container.hide();
        that.setState({ elkTopSearchResults: [], searchKey: "" });
      }
    });
    /*if (navigator.geolocation) {     
     await navigator.geolocation.getCurrentPosition(this.getLatLon);
    }*/

    let userCity = localStorage.getItem("UserCity");
    let userCountry = localStorage.getItem("UserCountry");
    let userCountryName = localStorage.getItem("UserCountryName");
    let userCityID = localStorage.getItem("UserCityID");
    let userStateID = localStorage.getItem("UserStateID");
    if (userCity == "" || userCity == null) {
      try {
        let coords = await this.getPosition();
        let latitude = coords.latitude;
        let longitude = coords.longitude;
        console.log("Latitude is " + latitude);
        console.log("Longitude is " + longitude);
        //this.state({userLat:latitude,userLong:longitude});
        let userCityInformation = await Utils.GetUserCityByCoords(latitude, longitude);
        let cityname = "";
        let townname = "";
        try {
          cityname = userCityInformation.address.city;
        } catch (e) {
        }
        try {
          townname = userCityInformation.address.town;
        } catch (e) {
        }
        if (cityname != "" && cityname != "undefined" && cityname != null) {
          let objCity = await Utils.GetCityInfoFromJHDB(cityname);
          if (objCity.length > 0) {
            console.log(objCity[0]);
            let objCityFromDB = objCity[0];
            localStorage.setItem("UserCountry", objCityFromDB._source.countryid);
            localStorage.setItem("UserCountryName", objCityFromDB._source.countryname);
            localStorage.setItem("UserCity", objCityFromDB._source.title);
            localStorage.setItem("UserCityID", objCityFromDB._source.id);
            localStorage.setItem("UserStateID", objCityFromDB._source.stateid);
            localStorage.setItem("UserCityWeatherID", objCityFromDB._source.weatherid);
          }
        } else if (townname != "" && townname != "undefined" && townname != null) {
          let objTown = await Utils.GetCityInfoFromJHDB(townname);
          if (objTown.length > 0) {
            let objCityFromDB = objTown[0];
            localStorage.setItem("UserCountry", objCityFromDB._source.countryid);
            localStorage.setItem("UserCountryName", objCityFromDB._source.countryname);
            localStorage.setItem("UserCity", objCityFromDB._source.title);
            localStorage.setItem("UserCityID", objCityFromDB._source.id);
            localStorage.setItem("UserStateID", objCityFromDB._source.stateid);
            localStorage.setItem("UserCityWeatherID", objCityFromDB._source.weatherid);
          }
        }
      }
      catch (e) {
      }
    }
    this.setState({ isLocationLoading: false });
    userCity = localStorage.getItem("UserCity");
    userCountry = localStorage.getItem("UserCountry");
    userCountryName = localStorage.getItem("UserCountryName");
    userCityID = localStorage.getItem("UserCityID");
    userStateID = localStorage.getItem("UserStateID");
    let userCityWeatherid = localStorage.getItem("UserCityWeatherID");
    if (userCity != null && userCity != "") {
      this.setState({
        Country: userCountry, selectedCountry: userCountryName, City: userCity, CityID: userCityID, isCityAvailable: true, StateID: userStateID, mainDataLoading: true
      });
      // Main categories
      let elkcategories = await Utils.GetCategoryItems();
      let filteredCategories = elkcategories.filter(function (elkCat) { return elkCat._source.isactive === true && elkCat._source.recordtype === 'Business' });
      let sortByCategories = _.sortBy(filteredCategories, (e) => {
        return e._source.title
      });
      // Sub categories
      let elksubcategories = await Utils.GetSubCategoryItems();
      let filteredSubCategories = elksubcategories.filter(function (elkSubCat) {
        return elkSubCat._source.isactive === true
      });
      let sortBySubCategories = _.sortBy(filteredSubCategories, (e) => {
        return e._source.title
      });
      this.setState({
        Categories: sortByCategories, SubCategories: sortBySubCategories
      });
      let weatherInfo = null;
      try {
        weatherInfo = await Utils.GetWeatherInfo(userCity);
      } catch (e) {
      }
      if (weatherInfo != null) {
        let weatherTemp = Number(weatherInfo.main.temp).toFixed(1);
        this.setState({
          Temperature: weatherTemp, Claimate: weatherInfo.weather[0].main,
          ClaimateCode: `http://openweathermap.org/img/wn/${weatherInfo.weather[0].icon}@2x.png`
        });
      }

      if (userCityWeatherid != null && userCityWeatherid != "") {
        var weatherURL = "https://openweathermap.org/city/" + userCityWeatherid;
        this.setState({ WeatherSiteURL: weatherURL });
      } else {
        this.setState({ WeatherSiteURL: "https://openweathermap.org" });
      }
      try {
        let franchiseownerdetails = await Utils.GetFranchiseOwnersForCity(this.state.CityID);
        this.setState({ franchiseOwner: franchiseownerdetails, franchiseoOwnerLoading: false });
      } catch (e) {

      }
      // Get all businessentityid for city
      let allBusinessEntities = await Utils.GetAllBuinessForCity(userCity);
      // Get all advertisement for city
      let allAdtisements = await Utils.GetBusinessRanking(userCityID);

      this.setState({
        AllBusinessForCity: allBusinessEntities, AllBusinessRanksForCity: allAdtisements, mainDataLoading: false
      });
      let adv3 = [];
      let adv3gifname = [];
      let adv3now = moment().format("YYYY-MM-DD");
      adv3 = allAdtisements.filter(function (elkItem) {
        //return elkItem._source.advtypeid === 3 && new Date() >= new Date(elkItem._source.startdate) && new Date() <= new Date(elkItem._source.tilldate)
        return elkItem._source.advtypeid === 3 && moment(adv3now, "YYYY-MM-DD").diff(moment(elkItem._source.startdate, "YYYY-MM-DD")) >= 0 && moment(elkItem._source.tilldate, "YYYY-MM-DD").diff(moment(adv3now, "YYYY-MM-DD")) >= 0
      });
      console.log(adv3);
      if (adv3.length > 0) {
        console.log("zzzz");
        console.log(adv3[0]._source.businessentityid);
        adv3gifname = allBusinessEntities.filter(function (elkItem) {
          return elkItem._source.id === adv3[0]._source.businessentityid
        });
        //Number(adv3[0]._source.businessentityid)
        console.log(adv3gifname);
        if (adv3gifname.length > 0 && adv3gifname[0]._source.gifname != "") {
          this.setState({ adv3gif: adv3gifname[0]._source.gifname, isAdv3Loading: false, adv3Cat: adv3gifname[0]._source.businessname, adv3CatID: adv3gifname[0]._source.id });
        } else {
          this.setState({ adv3gif: "NA", isAdv3Loading: false });
        }
      } else {
        this.setState({ adv3gif: "NA", isAdv3Loading: false });
      }
      let countryList = await Utils.GetCountryItems();
      let uniqCountryList = [];
      uniqCountryList = _.sortBy(countryList, (e) => {
        return e._source.title
      })
      let stateList = await Utils.GetStateItems();
      let uniqStateList = [];
      uniqStateList = _.sortBy(stateList, (e) => {
        return e._source.title
      })
      let citiesList = await Utils.GetAllCities();
      let uniqcitiesList = [];
      uniqcitiesList = _.sortBy(citiesList, (e) => {
        return e._source.title
      })
      let advTypes = await Utils.GetAdvertisementTypes();
      this.setState({ Countries: uniqCountryList, States: uniqStateList, Cities: uniqcitiesList, AdvertisementTypes: advTypes });
      return;
    }
    let geocodeStatus = "";
    let countryList = await Utils.GetCountryItems();
    let uniqCountryList = [];
    uniqCountryList = _.sortBy(countryList, (e) => {
      return e._source.title
    })
    let stateList = await Utils.GetStateItems();
    let uniqStateList = [];
    uniqStateList = _.sortBy(stateList, (e) => {
      return e._source.title
    })
    let citiesArray = await Utils.GetAllCities();
    let uniqueCitiesArray = [];
    uniqueCitiesArray = _.sortBy(citiesArray, (e) => {
      return e._source.title
    })
    let elkMajorCities=uniqueCitiesArray.filter(e=>e._source.ismajor===true);
    let advTypes = await Utils.GetAdvertisementTypes();
    // localStorage.setItem("lsCountries", JSON.stringify(uniqCountryList));
    //localStorage.setItem("lsStates", JSON.stringify(uniqStateList));
    this.setState({ Countries: uniqCountryList, States: uniqStateList, Cities: uniqueCitiesArray,elkCities:elkMajorCities, AdvertisementTypes: advTypes });
    // Main categories
    let elkcategories = await Utils.GetCategoryItems();
    let filteredCategories = elkcategories.filter(function (elkCat) { return elkCat._source.isactive === true && elkCat._source.recordtype === 'Business' });
    let sortByCategories = _.sortBy(filteredCategories, (e) => {
      return e._source.title
    });
    // Sub categories
    let elksubcategories = await Utils.GetSubCategoryItems();
    let filteredSubCategories = elksubcategories.filter(function (elkSubCat) {
      return elkSubCat._source.isactive === true
    });
    let sortBySubCategories = _.sortBy(filteredSubCategories, (e) => {
      return e._source.title
    });

    this.setState({ Categories: sortByCategories, SubCategories: sortBySubCategories });

  }
  render() {
    let { franchisesnackbaropen, successMsg, Country, subscribeemail, subscribeerror, CompName, isVoiceRecording, vertical, horizontal } = this.state;
    const { transcript, resetTranscript, browserSupportsSpeechRecognition, stopListening, startListening, listening } = this.props
    //const { transcript, resetTranscript } = this.props;
    // console.log('transcript ' + transcript);
    // console.log(listening);

    console.log("CompName",this.state.CompName);
    return (
      <Router>
        {this.state.IsMobilelected && <div><Dialog open={this.state.mobileappopen} maxWidth='sm' fullWidth={true} onClose={this.MobileAppClose} aria-labelledby="form-dialog-title">
          <DialogTitle classes={{ root: 'jh-franchise-dailog-title' }} id="customized-dialog-title">
            <span>JaiHo Mobile App</span><span className="pull-right" onClick={this.MobileAppClose}><i className="fa fa-times jh-mdl-appdownload-close" /></span>
          </DialogTitle>
          <DialogContent>
            <div className="container">
              <div className="row">
                <div className="col-md-4 col-lg-4 col-12">
                  <div className="form-group">
                    <label>Android</label>
                  </div>
                </div>
                <div className="col-md-8 col-lg-8 col-12">
                  <div className="form-group">
                    <img src={barcode1} />
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-12">
                  <div className="form-group">
                    <label>IOS</label>
                  </div>
                </div>
                <div className="col-md-8 col-lg-8 col-12">
                  <div className="form-group">
                    <img src={barcode2} />
                  </div>
                </div>
              </div>
            </div>
          </DialogContent>
        </Dialog>
        </div>}

        <div>
          <Dialog open={listening} maxWidth='xs' fullWidth={true} onExiting={this.SearchPopupClose} aria-labelledby="form-dialog-title">
            <DialogContent>
              <div className="row">
                <div className="col-md-10 col-lg-10 col-xs-10"><span className="txtlisten">
                  {transcript.length === 0 && <span> Speak out! </span>} {transcript}
                </span>
                </div>
                <div className="col-xs-2 col-md-2 col-lg-2">
                  <span>
                    <i className="fa fa-microphone listeningicon"></i>
                  </span>
                </div>
              </div>
            </DialogContent>
          </Dialog>
        </div>

        {this.state.IsFranchiseSelected && <div><Dialog open={this.state.franchiseopen} maxWidth='sm' fullWidth={true} onClose={this.FranchiseClose} aria-labelledby="form-dialog-title">
          <DialogTitle classes={{ root: 'jh-franchise-dailog-title' }} id="customized-dialog-title">
            <span>JaiHo Franchise Request</span><span className="pull-right" onClick={this.FranchiseClose}><i className="fa fa-times jh-mdl-appdownload-close" /></span>
          </DialogTitle>

          <DialogContent>
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-6 col-12">
                  <div className="form-group">
                    <label>Country <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="country" value={this.state.franchiseFields.country} onChange={this.franchiseHandleChange} />
                    <span className="text-danger">{this.state.franchiseErrors.country}</span>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-12">
                  <div className="form-group">
                    <label>State <small className="text-danger">*</small></label>
                    <input type="text" name="userstate" className="form-control" value={this.state.franchiseFields.userstate} onChange={this.franchiseHandleChange} />
                    <span className="text-danger">{this.state.franchiseErrors.userstate}</span>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-12">
                  <div className="form-group">
                    <label>City <small className="text-danger">*</small></label>
                    <input type="text" name="city" className="form-control" value={this.state.franchiseFields.city} onChange={this.franchiseHandleChange} />
                    <span className="text-danger">{this.state.franchiseErrors.city}</span>
                  </div>
                </div>
                <div className="col-md-6 col-lg-6 col-12">
                  <div className="form-group">
                    <label>Name <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="username" value={this.state.franchiseFields.username} onChange={this.franchiseHandleChange} />
                    <span className="text-danger">{this.state.franchiseErrors.username}</span>
                  </div>
                </div>

              </div>
              <div className="row">
                <div className="col-md-6 col-lg-6 col-12">
                  <div className="form-group">
                    <label>Email <small className="text-danger">*</small></label>
                    <input type="email" className="form-control" name="email" value={this.state.franchiseFields.email} onChange={this.franchiseHandleChange} />
                    <span className="text-danger">{this.state.franchiseErrors.email}</span>
                  </div>
                </div>
                <div className="col-md-2 col-lg-2 col-12">
                  <div className="form-group">
                    <label>Area Code</label>
                    <input type="text" className="form-control" name="code" value={this.state.franchiseFields.code} onChange={this.franchiseHandleChange} />
                    <span className="text-danger">{this.state.franchiseErrors.code}</span>
                  </div>
                </div>
                <div className="col-md-4 col-lg-4 col-12">
                  <div className="form-group">
                    <label>Phone <small className="text-danger">*</small></label>
                    <input type="text" className="form-control" name="phone" value={this.state.franchiseFields.phone} onChange={this.franchiseHandleChange} />
                    <span className="text-danger">{this.state.franchiseErrors.phone}</span>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 form-group">
                  <label>Comments / Additional Information</label>
                  <textarea className="form-control" name="comments" maxLength={1000} rows={2} cols={2} value={this.state.franchiseFields.comments} onChange={this.franchiseHandleChange} />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 col-lg-6 col-12">
                  <button type="button" className="btn btn-primary btn-block" onClick={this.submitFranchiseForm}>Submit</button>
                </div>
                <div className="col-md-6 col-lg-6 col-12">
                  <button type="button" className="btn btn-secondary btn-block" onClick={this.FranchiseClose}>Cancel</button>
                </div>
              </div>
            </div>
          </DialogContent>
          <DialogActions>




          </DialogActions>
        </Dialog>
        </div>}
        {(!this.state.isLocationLoading && (!this.state.isCityAvailable || this.state.IsCitySelected === true)) && <div> <Dialog classes={{ paper: 'city-dailog-wrapper' }} open={this.state.open} maxWidth='sm' fullWidth={true} onClose={this.handleClose} aria-labelledby="form-dialog-title">
          <DialogTitle id="customized-dialog-title">
            <a href="#" className="pull-right"><i className="fa fa-times" onClick={this.handleClose} style={{ color: '#333', fontSize: '24px' }}></i> </a>
          </DialogTitle>
          <DialogActions>

            <DialogContent className="jh-location-dialog-dialog-content">
              <div className="row jh-location-dialog">
                <div className="col-md-12">
                  <div class="input-group">
                    <input
                      type="text"
                      value={this.state.citykey}
                      onChange={this.GetCitySerachResultsWrapper}
                      className="form-control city-txt"
                      placeholder="Type city name"
                    />
                    <div class="input-group-append">
                      <div class="input-group-text city-search-clear">
                        <span><i className="fa fa-search" title="search"></i></span>
                      </div>
                    </div>
                  </div>
                  {this.state.elkCities.length>0 && (<div className="citiesWrapper">
                    <ul className="list-group citiesWrapperUL">
                      {this.state.elkCities.length > 0 && this.state.elkCities.map((tile) => (
                        <li className="list-group-item jh-ta-city-search-item" onClick={() => this.handleContinue(tile._source.title, tile._source.id, tile._source.stateid, tile._source.countryid, tile._source.weatherid)}>
                          {tile._source.title}
                        </li>
                      ))}
                    </ul>
                  </div>)}
                </div>
              </div>
            </DialogContent>


          </DialogActions>
        </Dialog></div>}
        {!this.state.isLoading && this.state.isCityAvailable && (<header className="header sticky-top jh-sticky-top">
          <div className="tri-color-bar w-100">
            <div className="tri-color-line tri-color-line-orange"></div>
            <div className="tri-color-line"></div>
            <div className="tri-color-line tri-color-line-green"></div>
          </div>

          <div className="header-inner">
            <div className="container header-container">
              <div className="row header-row m-0">
                <div className="col-lg-1 col-md-1 col-sm-6 cols-xs-6 brand-logo-container">
                  <div className="logo">
                    <Link to="/"><img src={Logo} alt="#" /></Link>
                  </div>
                  <div className="mobile-menu"></div>
                </div>
                <div className="col-lg-2 col-md-2 col-sm-6 col-xs-6 d-flex align-items-center app-container">
                  <div className="row">
                    <div className="col-12  p-0">
                      <p className="jh-header-app-download mb-3"><a href="#" onClick={this.FranchiseClick} className="JaiFranchiseTXT jh-header-app-block">
                        <span className="jh-header-app-icon"><i className="fa fa-globe"></i></span>
                        <span className="jh-header-app-text blink jh-franchise-text">Franchise your city?</span>
                      </a></p>
                      <p className="jh-header-app-download">
                        <a href="#" onClick={this.ScrollToBottom} className="JaiAppDownloadTXT jh-header-app-block d-flex justify-content-center">
                          <span className="jh-header-app-icon"><i className="fa fa-mobile"></i></span>
                          <span className="jh-header-app-text mobblink jh-franchise-text jh-mob-text">Download JaiHo App</span>
                        </a>
                      </p>
                      <div className="top-header-widget search-mobile" style={{ paddingTop: '0px' }}>
                    <div className="jh-city-weather-block p-0">
                      <div className="jh-city-weather-block-inner">
                        

                      </div>
                    </div>
                    <div className="jh-header-search">                     

                      <div className="input-group">
                        <div className="input-group-prepend jh-header-search-icon">
                          <div className="input-group-text"><Link to="/search" className="jh-search-icon"
                            //className={this.state.searchKey.length>3?"btn btn-search search-btn":"btn btn-search search-btn search-btn-disable"}

                            onClick={this.ClearTopResults}
                          >
                            <span>
                              <i className="fa fa-search" title="Search"></i>
                            </span>
                          </Link></div>
                        </div>
                        <input
                          type="text"
                          value={this.state.searchKey}
                          onChange={this.GetELKSerachResults}
                          className="form-control"
                          placeholder="Enter your search key word"
                        />
                        {!isVoiceRecording && (<a href="#"
                          //className={this.state.searchKey.length>3?"btn btn-search search-btn":"btn btn-search search-btn search-btn-disable"}
                          className="btn btn-search search-btn"
                          //onClick={this.ClearTopResults}
                          onClick={() => {
                            this.setState({ searchKey: "" })
                            this.props.startListening()
                            this.setState({ searchKey: transcript, isVoiceRecording: true })
                          }}
                        >
                          <span>
                            <i className="fa fa-microphone" title="Start Listening"></i>
                          </span>
                        </a>)}
                        {isVoiceRecording && <a href="#" className="btn btn-search search-btn" onClick={() => {
                          this.props.stopListening()
                          this.GetVoiceSearchResults(transcript)
                        }}>
                          <span>
                            <i className="fa fa-check" title="Stop Listening"></i>
                          </span>
                        </a>}
                      </div>
                    </div>
                    {this.state.searchKey && (<div className="searchResultsWrapper">
                      <ul className="list-group searchResultsWrapperUL">
                        {this.state.elkTopSearchResults.length > 0 && this.state.elkTopSearchResults.map((tile) => (
                          <li className="list-group-item jh-ta-search-item">
                            <Link to={`/entity/${tile._source.businessname.replace(/[^a-zA-Z0-9]/g, '')}/${tile._source.id}`} onClick={() => this.GoToEntity()} className="nav-link search-item">{tile._source.businessname}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>)}
                  </div>

                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-sm-12 col-xs-12  search-container">
                  <div className="top-header-widget" style={{ paddingTop: '0px' }}>
                    <div className="jh-city-weather-block p-0">
                      <div className="jh-city-weather-block-inner">
                      {!this.state.IsCitySearchSelected && (<div className="jh-city-weather">
                          <p href="#" className="jh-city-block d-flex align-items-center">
                            <span className="jh-city-block-inner" onClick={() => this.EnableCitySearch()}>
                              <i className="fa fa-map-marker jh-city-block-icon" ></i>
                              <span className="jh-city-block-city-name">{this.state.City}</span>
                              <i className="fa fa-chevron-down fadown jh-city-block-icon" ></i>
                            </span>
                            <a
                              href={this.state.WeatherSiteURL} target="_blank"
                              className="jh-weather-block d-flex p-0">
                              <span className="jh-weather-block-inner">
                                <span className="jh-weather-block-msg">{this.state.Temperature ? `${this.state.Temperature}°C ${this.state.Claimate}` : 'Weather Info'}</span>
                              </span>
                            </a>
                            <span className="jh-city-weather-icon">
                              <img src={this.state.ClaimateCode} className="weathericon" />
                            </span>
                          </p>
                        </div>)}
                        {this.state.IsCitySearchSelected && <div className="city-search">
                        <div class="input-group">
                          <input
                            type="text"
                            value={this.state.searchCityKey}
                            onChange={this.GetCitySerachResults}
                            className="form-control city-txt"
                            placeholder="Type city name"
                          />
                          <div class="input-group-append">
                            <div class="input-group-text city-search-clear">
                              <a href="#" onClick={() => this.ClearCitySearch()}><span><i class="fa fa-remove city-search-clear-fa" title="Clear"></i></span></a>
                            </div>
                          </div>
                        </div>
                      </div>}
                      {this.state.elkTopCitySearchResults.length > 0 && (<div className="citysearchResultsWrapper">
                        <ul className="list-group citysearchResultsWrapperUL">
                          {this.state.elkTopCitySearchResults.length > 0 && this.state.elkTopCitySearchResults.map((tile) => (
                            <li className="list-group-item jh-ta-city-search-item" onClick={() => this.handleContinue(tile._source.title, tile._source.id, tile._source.stateid, tile._source.countryid, tile._source.weatherid)}>
                              {tile._source.title}
                            </li>
                          ))}
                        </ul>
                      </div>)}
                      </div>
                    </div>
                    <div className="jh-header-search">
                      {/* <div className="jh-header-app-download">
                        <a href="#" onClick={this.ScrollToBottom} className="JaiAppDownloadTXT jh-header-app-block d-flex justify-content-center">
                          <span className="jh-header-app-icon"><i className="fa fa-mobile"></i></span>
                          <span className="jh-header-app-text mobblink jh-franchise-text jh-mob-text">Download JaiHo App</span>
                        </a>

                        <a href="#" onClick={this.FranchiseClick} className="JaiFranchiseTXT jh-header-app-block float-right">
                          <span className="jh-header-app-icon"><i className="fa fa-globe"></i></span>
                          <span className="jh-header-app-text blink jh-franchise-text">Interested in JaiHo Franchise for your city</span>
                        </a>
                      </div> */}

                      <div className="input-group">
                        <div className="input-group-prepend jh-header-search-icon">
                          <div className="input-group-text"><Link to="/search" className="jh-search-icon"
                            //className={this.state.searchKey.length>3?"btn btn-search search-btn":"btn btn-search search-btn search-btn-disable"}

                            onClick={this.ClearTopResults}
                          >
                            <span>
                              <i className="fa fa-search" title="Search"></i>
                            </span>
                          </Link></div>
                        </div>
                        <input
                          type="text"
                          value={this.state.searchKey}
                          onChange={this.GetELKSerachResults}
                          className="form-control"
                          placeholder="Enter your search key word"
                        />
                        {!isVoiceRecording && (<a href="#"
                          //className={this.state.searchKey.length>3?"btn btn-search search-btn":"btn btn-search search-btn search-btn-disable"}
                          className="btn btn-search search-btn"
                          //onClick={this.ClearTopResults}
                          onClick={() => {
                            this.setState({ searchKey: "" })
                            this.props.startListening()
                            this.setState({ searchKey: transcript, isVoiceRecording: true })
                          }}
                        >
                          <span>
                            <i className="fa fa-microphone" title="Start Listening"></i>
                          </span>
                        </a>)}
                        {isVoiceRecording && <a href="#" className="btn btn-search search-btn" onClick={() => {
                          this.props.stopListening()
                          this.GetVoiceSearchResults(transcript)
                        }}>
                          <span>
                            <i className="fa fa-check" title="Stop Listening"></i>
                          </span>
                        </a>}
                      </div>
                    </div>
                    {this.state.searchKey && (<div className="searchResultsWrapper">
                      <ul className="list-group searchResultsWrapperUL">
                        {this.state.elkTopSearchResults.length > 0 && this.state.elkTopSearchResults.map((tile) => (
                          <li className="list-group-item jh-ta-search-item">
                            <Link to={`/entity/${tile._source.businessname.replace(/[^a-zA-Z0-9]/g, '')}/${tile._source.id}`} onClick={() => this.GoToEntity()} className="nav-link search-item">{tile._source.businessname}</Link>
                          </li>
                        ))}
                      </ul>
                    </div>)}
                  </div>
                </div>
                <div className="col-lg-5 col-md-3 col-sm-12 col-xs-12 d-flex align-items-center banner-add-container p-0">
                  <div className="top-header-widget top-header-widget-banner">
                    {this.state.adv3gif !== "NA" && <Link className="jh-banner-image" to={`/entity/${this.state.adv3Cat}/${this.state.adv3CatID}`}>
                      {this.state.isAdv3Loading && <Skeleton count={1} height={50} />}
                      {!this.state.isAdv3Loading && <img src={`https://admin.jaiho.com/assets/businessimg/${this.state.adv3gif}`} />}
                    </Link>}
                    {this.state.adv3gif === "NA" && <Link className="jh-banner-image" to="/advertise">
                      {this.state.isAdv3Loading && <Skeleton count={1} height={50} />}
                      {!this.state.isAdv3Loading && <img src={adv3nodata} />}
                    </Link>}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="header-menu">
            <div className="container pl-0">
              <div className="row jh-yellow-main-content">
                <div className="col-lg-2 col-md-3 yellow-nav-block pr-2">
                  <div className="yellow-nav-block-inner" onClick={() => this.sidebarToggle()}>
                    <span className="yellow-nav-link">Yellow Pages</span>
                    <span className="yellow-nav-icon">
                      <i className="fa fa-angle-double-left"></i>
                    </span>
                  </div>
                </div>
                <div className="col-lg-10 col-md-9 mainNav p-0">
                  <nav className="navbar navbar-expand-lg navbar-dark bg-dark-purple">


                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarPurpleContent" aria-controls="navbarPurpleContent" aria-expanded="false" aria-label="Toggle navigation">
                      <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarPurpleContent">
                      <ul className="navbar-nav mt-2 mt-lg-0">
                        <li className={CompName === "home" || CompName === "Poll" || CompName === "Entity" || CompName === "Tiles" ? "nav-item mn-hvr active" : "nav-item mn-hvr"} >
                          <Link to="/" className="nav-link">Home</Link>
                        </li>
                        <li className={CompName === "Message Board" ? "nav-item mn-hvr active" : "nav-item mn-hvr"}>
                          <Link to="/discussion" className="nav-link">Message Board</Link>
                        </li>
                        <li className={CompName === "Events" ? "nav-item mn-hvr active" : "nav-item mn-hvr"}>
                          <Link to="/events/all" className="nav-link">Events</Link>
                        </li>
                        {/* <li className="nav-item mn-hvr">
                          <a href="https://admin.jaiho.com" className="nav-link" target="_blank">Add your Business</a>
                        </li> */}
                        <li className={CompName === "AddBusiness" ? "nav-item mn-hvr active" : "nav-item mn-hvr"}>
                          <Link to="/addbusiness" className="nav-link">Add your Business</Link>
                        </li>
                        <li className={CompName === "Advertise" ? "nav-item mn-hvr active" : "nav-item mn-hvr"}>
                          <Link to="/advertise" className="nav-link">Advertise</Link>
                        </li>
                        <li className={CompName === "Awards" ? "nav-item mn-hvr active" : "nav-item mn-hvr"}>
                          <Link to="/awards" className="nav-link">Awards</Link>
                        </li>
                        <li className={CompName === "UsefulLinks" ? "nav-item mn-hvr active" : "nav-item mn-hvr"}>
                          <Link to="/usefullinks/all" className="nav-link">Useful Links</Link>
                        </li>
                        <li className={CompName === "About" ? "nav-item mn-hvr active" : "nav-item mn-hvr"}>
                          <Link to="/about" className="nav-link">About us</Link>
                        </li>
                        <li className={CompName === "Contact" ? "nav-item mn-hvr active" : "nav-item mn-hvr"}>
                          <Link to="/contact" className="nav-link">Contact us</Link>
                        </li>
                        <li className="nav-item mn-hvr">
                          <a href="https://admin.jaiho.com" className="nav-link" target="_blank">Login</a>
                        </li>
                        <li className={CompName === "Trends" ? "nav-item mn-hvr active" : "nav-item mn-hvr"}>
                          <Link to="/trends" className="nav-link">Trending</Link>
                        </li>
                      </ul>
                    </div>
                  </nav>


                </div>
              </div>
            </div>
          </div>
        </header>
        )}

        <div className="row mr-0" id="jh-wrapper">
          {!this.state.isLoading && this.state.isCityAvailable && <div className="col-lg-2 col-md-3 border-right pr-0" id="jh-sidebar-wrapper">
            <div className="leftNav">
              <YellowPagesNavigation categories={this.state.Categories} subCategories={this.state.SubCategories}></YellowPagesNavigation>
            </div>
          </div>}
          <div className="col-lg-10 col-md-9 jh-main-content jh-inner-page-main-content p-0">
            {!this.state.isLoading && this.state.isCityAvailable && <Route
              exact
              path='/'
              render={(props) => (
                <Home {...props} componentName={this.IsComponetLaoded}
                  country={this.state.selectedCountry}
                  countryid={this.state.Country}
                  city={this.state.City}
                  cityid={this.state.CityID}
                  stateid={this.state.StateID}
                  allBusinessItems={this.state.AllBusinessForCity}
                  allBusinessRankItems={this.state.AllBusinessRanksForCity}
                  isMainDataLoading={this.state.mainDataLoading} />
              )}
            />
            }
            <Route
              path='/discussion'
              render={(props) => (
                <DiscussionPage {...props} componentName={this.IsComponetLaoded} />
              )}
            />
            <Route
              path='/about'
              render={(props) => (
                <About {...props} componentName={this.IsComponetLaoded} />
              )}
            />
            <Route
              path='/addbusiness'
              render={(props) => (
                <AddBusiness {...props} componentName={this.IsComponetLaoded}
                  successCallback={this.SnackbarCallback}
                  countries={this.state.Countries.filter((i)=>i._source.isactive==true).map((x)=>{
                        return  {
                            "countryId": x._source.id,
                            "countryName": x._source.title,
                        };
                })}
                  states={this.state.States.filter((i)=>i._source.isactive==true).map((x)=>{                   
                        return  {
                            "countryId": x._source.countryid,
                            "stateId": x._source.id,
                            "stateName":x._source.title
                        };
                })}
                  cities={this.state.Cities.filter((i)=>i._source.isactive==true).map((x)=>{                    
                        return  {
                            "countryId": x._source.countryid,
                            "stateId": x._source.stateid,
                            "cityId":x._source.id,
                            "cityName":x._source.title
                        };
                })}
                   />
              )}
            />
            <Route
              path='/advertise'
              render={(props) => (
                <Advertisements {...props} componentName={this.IsComponetLaoded}
                  successCallback={this.SnackbarCallback}
                  countries={this.state.Countries}
                  states={this.state.States}
                  cities={this.state.Cities}
                  categories={this.state.Categories}
                  subcategories={this.state.SubCategories}
                  advertisementtypes={this.state.AdvertisementTypes} />
              )}
            />
            <Route
              path='/events/:id'
              render={(props) => (
                <Events {...props} componentName={this.IsComponetLaoded} allBusinessItems={this.state.AllBusinessForCity} />
              )}
            />
            <Route
              path='/employment'
              render={(props) => (
                <Employment {...props} componentName={this.IsComponetLaoded} cityid={this.state.CityID} />
              )}
            />
            <Route
              path='/jobregistration'
              render={(props) => (
                <JobRegistration {...props} componentName={this.IsComponetLaoded}
                  successCallback={this.SnackbarCallback}
                  countries={this.state.Countries}
                  states={this.state.States}
                  cities={this.state.Cities} />
              )}
            />
            <Route
              path='/contact'
              render={(props) => (
                <Contact {...props} componentName={this.IsComponetLaoded} successCallback={this.SnackbarCallback} countries={this.state.Countries} states={this.state.States} />
              )}
            />
            <Route
              path='/awards'
              render={(props) => (
                <Awards {...props} componentName={this.IsComponetLaoded} />
              )}
            />
            <Route exact
              path='/usefullinks/:id'
              render={(props) => (
                <UsefulLinks {...props} componentName={this.IsComponetLaoded} cityid={this.state.CityID} countryid={this.state.Country}  />
              )}
            />
            <Route
            exact
              path='/usefullinks/:cat/:id'
              render={(props) => (
                <UsefulLinksByCat {...props} componentName={this.IsComponetLaoded} cityid={this.state.CityID} countryid={this.state.Country}  />
              )}
            />
            <Route
              path='/entity/:buz/:id'
              render={(props) => (
                <Entity {...props} componentName={this.IsComponetLaoded} />
              )}
            />
            <Route
              path='/employmententity/:id'
              render={(props) => (
                <EmploymentEntity {...props} componentName={this.IsComponetLaoded} />
              )}
            />
            <Route
              path='/polling/:country/:city/:id'
              render={(props) => (
                <Polling {...props} componentName={this.IsComponetLaoded} successCallback={this.SnackbarCallback} />
              )}
            />
            <Route path="/createtopic" component={CreateTopic} />
            <Route path="/postreply/:rowid" component={PostReply} />
            <Route path="/disclaimerPage/:rowid" component={DisclaimerPage} />
            <Route path="/createTopicDisclaimerPage" component={CreateTopicDisclaimerPage} />
            <Route exact path='/yellowpages/:cat/:id'
              render={(props) => (
                <AllServices {...props} allEntities={this.state.AllBusinessForCity} categories={this.state.Categories} subcategories={this.state.SubCategories} componentName={this.IsComponetLaoded} city={this.state.City} cattype="main" />
              )}
            />
            <Route exact path='/yellowpages'
              render={(props) => (
                <AllServices {...props} allEntities={this.state.AllBusinessForCity} categories={this.state.Categories} subcategories={this.state.SubCategories} componentName={this.IsComponetLaoded} city={this.state.City} cattype="yellow" />
              )}
            />
            <Route exact path='/yellowpages/:cat/:subcat/:id'
              render={(props) => (
                <AllServices {...props} allEntities={this.state.AllBusinessForCity} categories={this.state.Categories} subcategories={this.state.SubCategories} componentName={this.IsComponetLaoded} city={this.state.City} cattype="sub" />
              )}
            />
            <Route path='/eventsentitycat/:id'
              render={(props) => (
                <EventServices {...props} componentName={this.IsComponetLaoded} city={this.state.City} />
              )}
            />
            <Route path='/search'
              render={(props) => (
                <Search {...props} componentName={this.IsComponetLaoded} city={this.state.City} searchKeyword={this.state.allSearchKey} />
              )}
            />
            <Route
              path='/trends'
              render={(props) => (
                <Trends {...props} componentName={this.IsComponetLaoded} countryid={this.state.Country} cityid={this.state.CityID} />
              )}
            />
          </div>
        </div>



        {!this.state.isLoading && this.state.isCityAvailable && (<footer className="footer overlay section"><div className="footer-inner">
          {this.state.CompName == "home" && (<div className="footer-top">
            <div className="container">
              <div className="row">
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="single-widget about footer-about">
                    <h2>About JaiHo</h2>
                    {!this.state.franchiseoOwnerLoading && (<div className="logo logo-footer">
                      {this.state.franchiseOwner.length === 0 &&
                        <a href="index.html"><img src={Logo} alt="#" /></a>}
                      {this.state.franchiseOwner.length > 0 && !this.state.franchiseOwner[0]._source.showdp &&
                        <a href="index.html"><img src={Logo} alt="#" /></a>}
                      {this.state.franchiseOwner.length > 0 && this.state.franchiseOwner[0]._source.showdp &&
                        <a href="index.html"><img src={`https://admin.jaiho.com/assets/img/avatars/${this.state.franchiseOwner[0]._source.picture}`} alt="#" /></a>}
                    </div>)}

                    <p className="logo-footer-description">JaiHo is the best community website for Indians and it provides information on restaurants, grocery stores, travel agents, computer training, fashions and many more.</p>
                    {!this.state.franchiseoOwnerLoading && this.state.franchiseOwner.length === 0 && (<ul className="list">
                      <li><i className="fa fa-phone"></i>Phone: +91 9270123456</li>
                      <li><i className="fa fa-envelope"></i>Email: <a href="mailto:support@jaiho.com">support@jaiho.com</a></li>
                      <li><i className="fa fa-map-o"></i>Address: MSquare Suite 102 HITECH City, Madhapur, Hyderabad, TS 500081</li>
                    </ul>)}
                    {!this.state.franchiseoOwnerLoading && this.state.franchiseOwner.length > 0 && (
                      <ul className="list">
                        <li><i className="fa fa-phone"></i>Phone: {this.state.franchiseOwner[0]._source.contactno}</li>
                        <li><i className="fa fa-envelope"></i>Email: <a href={`mailto:${this.state.franchiseOwner[0]._source.email}`}>{this.state.franchiseOwner[0]._source.email}</a></li>
                        <li><i className="fa fa-map-o"></i>Designation: {this.state.franchiseOwner[0]._source.designation}</li>
                        <li><i className="fa fa-map-o"></i>Address: {this.state.franchiseOwner[0]._source.address}</li>
                        <li><i className="fa fa-map-o"></i>Website: {this.state.franchiseOwner[0]._source.website}</li>
                      </ul>
                    )}
                  </div>
                </div>
                <div className="col-lg-2 col-md-6 col-12">
                  <div className="single-widget useful-links">
                    <h2>Useful Links</h2>
                    <ul>
                      <li><Link to="/"><i className="fa fa-angle-right"></i>Home</Link></li>
                      <li><Link to="/discussion"><i className="fa fa-angle-right"></i>Message Board</Link></li>
                      <li><Link to="/events"><i className="fa fa-angle-right"></i>Events</Link></li>
                      <li><a href="https://admin.jaiho.com" target="_blank"><i className="fa fa-angle-right"></i>Add your Business</a></li>
                      <li><Link to="/about"><i className="fa fa-angle-right"></i>About Us</Link></li>
                      <li><Link to="/contact"><i className="fa fa-angle-right"></i>Contact Us</Link></li>
                      <li><a href="https://admin.jaiho.com" target="_blank"><i className="fa fa-angle-right"></i>Login</a></li>
                      <li><Link to="/trends"><i className="fa fa-angle-right"></i>Trending</Link></li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 col-md-6 col-12">
                  <div className="single-widget latest-news">
                    <h2>Advertise with us</h2>
                    <div className="news-inner">
                      <div className="single-news">
                        <img src={require('./img/abacus.jpg')} alt="#" />
                        <p>If you would like to advertise your products or services to our community visitors and users,
                          then you can do so at reasonable rates. Miindia.com will help customize your ad based on your
                          needs and budget. <a href="#">Click here</a> for more info.</p>
                      </div>
                    </div>
                  </div>
                  <div className="row qrcodes pt-3">
                    <div className="col-md-6 col-lg-6 col-sm-4">
                      <div className="pull-left">
                        <p className="qrtext">iOS</p>
                        <img className="qrcode" src={require('./img/ios.png')} alt="#" />
                      </div>
                    </div>
                    <div className="col-md-6 col-lg-6 col-sm-4">
                      <div className="pull-right">
                        <p className="qrtext">Android</p>
                        <img className="qrcode" src={require('./img/android.png')} alt="#" />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-6 col-12">
                  <div className="single-widget newsletter">
                    <h2>Subscribe Newsletter</h2>
                    <div className="mail">
                      <p>Don't miss to  subscribe to our news feed, Get the latest updates from us!</p>
                      <div className="form">
                        <input type="email" name="subscribeemail" value={subscribeemail} placeholder="Enter your email" onChange={this.subscribeHandleChange} />
                        <span className="text-danger">{subscribeerror}</span>
                        <button className="button" onClick={this.subscribeClick}><i className="fa fa-envelope"></i></button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>)}
          {/* Footer Bottom */}
          <div className="footer-bottom">
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="bottom-head">
                    <div className="row">
                      <div className="col-12">
                        {/* Social */}
                        <ul className="social">
                          <li><a href="#"><i className="fa fa-twitter" /></a></li>
                          <li><a href="#"><i className="fa fa-facebook" /></a></li>
                          <li><a href="#"><i className="fa fa-google-plus" /></a></li>
                          <li><a href="#"><i className="fa fa-linkedin" /></a></li>
                          <li><a href="#"><i className="fa fa-youtube" /></a></li>
                        </ul>
                        {/* End Social */}
                        {/* Copyright */}
                        <div className="copyright">
                          <p>© Copyright 2020 <a href="#">JaiHo</a>. All Rights Reserved</p>
                        </div>
                        <div className="pull-right  jh-move-up-button">
                          <button type="button" id="scrollUp" className="pull-right" onClick={this.ScrollToTop}><i className="fa fa-angle-up"></i></button>
                        </div>
                        {/*/ End Copyright */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/*/ End Footer Bottom */}
        </div></footer>)}
        <Snackbar open={franchisesnackbaropen} autoHideDuration={3000} onClose={this.handleSnackbarClose} anchorOrigin={{ vertical, horizontal }}
        >
          <Alert onClose={this.handleSnackbarClose} severity="success">
            {successMsg}
          </Alert>
        </Snackbar>

      </Router>

    )
  }
}

export default SR(options)(App);
