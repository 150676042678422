import React, { forwardRef, useEffect } from "react";
import { useHistory } from "react-router";
import MaterialTable from "material-table";
import "@material-ui/core";
import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import ArrowUpward from "@material-ui/icons/ArrowUpward";
import YellowPagesNavigation from '../leftnav';
import Skeleton from '@material-ui/lab/Skeleton';
import * as _ from 'lodash';
import {
  Button,
  Avatar,
  Card,
  CardActions,
  CardActionArea,
  CardContent,
  Typography,
  CardHeader,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import AxiosApi from "./../../utilities/AxiosApi";
import ReplyIcon from "@material-ui/icons/Reply";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";
import PostAddIcon from "@material-ui/icons/PostAdd";
import CloseIcon from "@material-ui/icons/Close";
import Axios from 'axios';
import PostReply from "./PostReply";

const tableIcons = {
  Add: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <AddBox {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Check {...props} ref={ref} />
  )),
  Clear: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Clear {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <DeleteOutline {...props} ref={ref} />
  )),
  DetailPanel: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Edit {...props} ref={ref} />
  )),
  Export: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <SaveAlt {...props} ref={ref} />
  )),
  Filter: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <FilterList {...props} ref={ref} />
  )),
  FirstPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <FirstPage {...props} ref={ref} />
  )),
  LastPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <LastPage {...props} ref={ref} />
  )),
  NextPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ChevronRight {...props} ref={ref} />
  )),
  PreviousPage: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Clear {...props} ref={ref} />
  )),
  Search: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Search {...props} ref={ref} />
  )),
  SortArrow: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ArrowUpward {...props} ref={ref} />
  )),
  ThirdStateCheck: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <Remove {...props} ref={ref} />
  )),
  ViewColumn: forwardRef((props, ref: React.Ref<SVGSVGElement>) => (
    <ViewColumn {...props} ref={ref} />
  )),
};

var selectedid: Number = 0;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  small: {
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
  large: {
    width: theme.spacing(5),
    height: theme.spacing(5),
  },
  card: {
    //maxWidth: 720,
    marginTop: 24,
    marginLeft: 0,
  },
  media: {
    height: 140,
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  appBar: {
    position: "relative",
  },
}));

//function DiscussionPage() {
const DiscussionPage = (props: any) => {
  //props.componentName("Message Board");
  const [dashboardData, setDashboardData] = React.useState<any>([]);
  const [discussionReplyData, setDiscussionReplyData] = React.useState<any>([]);
  const [pageloading,setPageloading]=React.useState(true);
  const query = {
    query: {
      match: {
        cityname: " ",
      }    
    }
    };
  // useEffect(() => {
  //   AxiosApi.get("Discussion")
  //       .then((data) => {
  //       console.log("data " + JSON.stringify(data.data));
  //       setDashboardData(data.data);
  //     })
  //     .catch(() => { });
  // }, []);

  useEffect(() => {
	  props.componentName("Message Board");
    AxiosApi.get("/Discussion")
      .then((data) => { 
	  let discussions = _.orderBy(data.data,['createddate'],['desc']);  
        setDashboardData(discussions);
		setPageloading(false);
      })
      .catch(() => { });
  }, []);

  const updateViews = (data: any, id: Number) => {
    
    console.log("id" + id);
    console.log("data" + JSON.stringify(data));
    if(selectedid != id){
      selectedid = id;
      console.log("selectedid" + selectedid);
    fetch("https://api.jaiho.com/Discussion/"+selectedid,{
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: data.id,
        title: data.title,
        description: data.description,
        discussioncategoryid: data.discussioncategoryid,
        discussioncategoryname: data.discussioncategoryname,
        username: data.username,
        useremail: data.useremail,
        noofviews: 1,
        noofreplies: 0,
        isactive: data.isactive,
        createdby: data.createdby,
        createddate: data.createddate,
        modifiedby: data.modifiedby,
        modifieddate: data.modifieddate,
      }),
    }).then((response) => {
        console.log("Update Views " + JSON.stringify(response));
    }).catch((e) => {
        console.log("Post Reply error" + JSON.stringify(e));
    });
  }
  }

  const postReply = (rowData: any) => {
    console.log('rowData '+JSON.stringify(rowData));
    localStorage.setItem('rowData', JSON.stringify(rowData));
    history.push(`/disclaimerPage/${rowData.id}`);
  }

  const history = useHistory();

  const classes = useStyles();
  
  return (
  
     <div className="d-flex"  id="jh-wrapper">		 
			 <div id="jh-page-content-wrapper" className="jh-page-content-wrapper">
				<div className="row">
				  <nav aria-label="breadcrumb">
					<ol className="breadcrumb">
					  <li className="breadcrumb-item"><a href="#">Home</a></li>
					  <li className="breadcrumb-item active" aria-current="page">Discussion Board</li>
					</ol>
				  </nav>
				</div>
		<div className="jh-content-body">
		      
					 <div className="col-lg-12 jh-events-title">
						<h2 className="jh-title-1">Discussion Board</h2>
					</div>					
				
							
					<div className="col-12">
						
		        
			{pageloading && <div>
				<div style={{display:"flex"}}>
					<Skeleton variant="text" width={300} style={{height:60}} />
					<div style={{flexGrow:1}}/>
					<Skeleton variant="text" width={300} height={60} style={{marginLeft:32}} />					
				</div>
				<div style={{height:12}}></div>
				<div style={{display:"flex",margin:8}}>
					<Skeleton variant="text" width={40} height={45} />
					<div style={{width:20}}/>
					<Skeleton variant="text" width={"100%"} height={45} />					
				</div>
				<div style={{display:"flex",margin:8}}>
					<Skeleton variant="text" width={40} height={45} />
					<div style={{width:20}}/>
					<Skeleton variant="text" width={"100%"} height={45} />					
				</div>
				<div style={{display:"flex",margin:8}}>
					<Skeleton variant="text" width={40} height={45} />
					<div style={{width:20}}/>
					<Skeleton variant="text" width={"100%"} height={45} />					
				</div>
				<div style={{display:"flex",margin:8}}>
					<Skeleton variant="text" width={40} height={45} />
					<div style={{width:20}}/>
					<Skeleton variant="text" width={"100%"} height={45} />					
				</div>
				<div style={{display:"flex",margin:8}}>
					<Skeleton variant="text" width={40} height={45} />
					<div style={{width:20}}/>
					<Skeleton variant="text" width={"100%"} height={45} />					
				</div>
				<div style={{display:"flex",margin:8}}>
					<Skeleton variant="text" width={40} height={45} />
					<div style={{width:20}}/>
					<Skeleton variant="text" width={"100%"} height={45} />					
				</div>
				
			  </div>}
			  {!pageloading && <MaterialTable
          icons={tableIcons}          
          options={{
            headerStyle: {
              backgroundColor: "#823587",
              color: "#FFF"			 
            },
			 searchFieldAlignment:"left",			 
            search: true,
            sorting: true,
            actionsColumnIndex: -1,
          }}
		  title=""
          columns={[
            {
              title: "Forum Messages",
              field: "title",
              cellStyle: {
                width: "50%",
              },
            },
            {
              title: "Started",
              field: "username",
            },
            {
              title: "Posted",
              field: "createddate",
              type: 'date',
              cellStyle: {
                width: "10%",
              },
            },
            {
              title: "Replies",
              field: "noofreplies",
            },
            {
              title: "Views",
              field: "noofviews",
              cellStyle: {
                width: "30%",
                marginLeft: 0,
              },
            },
          ]}
          data={dashboardData != null ? dashboardData : dashboardData}
          actions={[
            {
              icon: () => <ReplyIcon style={{ color: "#823587" }} />,
              tooltip: "Reply",
              onClick: (event, rowDData: any) => postReply(rowDData),
            },
			{
              icon: () => <Button
          variant="outlined"
		  className="CreateTopic"
          startIcon={<PostAddIcon />}
          style={{
            display: "flex",
            color: "#fff",
            background: "#823587",
            marginLeft: "auto",
          }}		  
          
        >Create Topic</Button>,
			  isFreeAction: true,
              tooltip: "",
              onClick: ()=> history.push('/createTopicDisclaimerPage'),
            }
          ]}
          onRowClick={(event: any,rowData: any, togglePanel: any) => {
            console.log('event '+event);
            togglePanel();
            
          }}
          detailPanel = {(rowData: any) => {
            localStorage.setItem('rowData', JSON.stringify(rowData));
            updateViews(rowData, rowData.id);
            console.log('rowData '+JSON.stringify(rowData));
            console.log('discussionReplyData'+JSON.stringify(discussionReplyData));
            var repliesData: any = [];
            /*for(var j=0; j<discussionReplyData.length; j++){
              var discussionid: string = discussionReplyData[j].discussionid;
              var rowId: string = rowData.id;
              console.log('discussionid '+discussionid);
              console.log('rowId '+rowId);
              if( discussionid == rowId){
                console.log('Match');
                repliesData.push(discussionReplyData[j]);
              }else{
                console.log('No replies');
                //repliesData = [];
              }
            }*/
			let selectedDiscussion=dashboardData.filter(function (obj:any) { 					
						return obj.id== rowData.id});
			repliesData=selectedDiscussion[0].discussionreplies;
            console.log('index '+JSON.stringify(repliesData));
            return (
              <div>
                <div className="card">
                  <div className="card-body">
                    
                      <Card className={classes.card} >
                        <CardHeader className="jh-discussion-cardheader"
                          avatar={
                            <Avatar aria-label="recipe" className={classes.large}>
                              {rowData.username.charAt(0)}
                          </Avatar>
                          }
                          title={rowData.username}
                          subheader={new Date(rowData.createddate).toLocaleTimeString('en-US', 
                          { month: 'short', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' })}
                        />
						<small className="jh-discussion-userip">{rowData.userip}</small>
                        <CardActionArea>
                          <CardContent style={{ backgroundColor: '#f2f2f2' }}>
                            <Typography gutterBottom variant="h6" component="h2">
                              {rowData.title}
                          </Typography>
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {rowData.description}
                          </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            startIcon={<ThumbUpIcon />}
                          >
                            Like
                        </Button>
                        </CardActions>
                      </Card>
                      <div style={{ display: "flex", flexDirection: "row", marginTop: 24 }}>
                        {repliesData.length > 0 ?
                      <h6 style={{ marginTop: 12}}>View Replies:</h6>: null}
                      <Button
                        variant="outlined"
                        startIcon={<ReplyIcon />}
                        style={{
                          display: "flex",
                          color: "#fff",
                          background: "#823587",
                          marginLeft: "auto",
                        }}
                        onClick={()=> history.push(`/disclaimerPage/${rowData.id}`)}
                      >
                        Post reply
                      </Button>
                      </div>
                      {repliesData.length > 0 ?
                      repliesData.map((index: any)=>
                        <Card className={classes.card} >
                        <CardHeader className="jh-discussion-cardheader"
                          avatar={
                            <Avatar aria-label="recipe" className={classes.large}>
                              {index.username.charAt(0)} 								  
                            </Avatar>							
                          }
                          title={index.username}
                          subheader={new Date(index.createddate).toLocaleTimeString('en-US', 
                          { month: 'short', day: 'numeric', hour: 'numeric', hour12: true, minute: 'numeric' })}
                        />
						<small className="jh-discussion-userip">{index.userip}</small>
                        <CardActionArea>							
                          <CardContent style={{ backgroundColor: '#f2f2f2' }}>
                            
                            <Typography
                              variant="body2"
                              color="textSecondary"
                              component="p"
                            >
                              {index.message}
                          </Typography>
                          </CardContent>
                        </CardActionArea>
                        <CardActions>
                          <Button
                            size="small"
                            color="primary"
                            startIcon={<ThumbUpIcon />}
                          >
                            Like
                        </Button>
                        </CardActions>
                      </Card>
                      )
                      : null}
                    </div>
                  </div>
                </div>
            );
          }}
/>}
      </div>
    
					
				
			   </div>
			</div>
	 </div>
  
  
    
  );
}

export default DiscussionPage;
