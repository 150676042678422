import React from 'react';
import axios from 'axios';
const elkdns=process.env.REACT_APP_ELK_Endpoint;
const apiendpoint=process.env.REACT_APP_API_Endpoint;
export class HomeUtils { 
  public static GetSearchResultItems = async (city: any, searchKey: any, resultCount: any): Promise<any> => {
    let elkURl = elkdns +"/businessentity/_search?size=" + resultCount + "&_source=id,businessname,shortdescription,phone,website,latitude,longitude,createddate";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "bool": {
              "must": [{
                "query_string": {
                  "query": searchKey
                }
              },
              { "match": { "advcities": city } }]
            }
          }
        }
      );

      resolve(results.data.hits.hits);
    })
  }



  public static GetBusinessEntitiesByRank = async (obj: any): Promise<any> => {
    let elkURl = elkdns+"/businessentity/_search?_source=id,businessname";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "bool": {
              "should": [{ "match": { "businessid": 20000371 } }, { "match": { "businessid": 20000372 } },
              { "match": { "businessid": 20000373 } }, { "match": { "businessid": 20000376 } }, { "match": { "businessid": 20000379 } }]
            }
          }
        }
      );

      resolve(results.data.hits.hits);
    })
  }

  public static GetBusinessRanking = async (obj: any): Promise<any> => {
    let elkURl = elkdns+"/advertisement/_search?size=10000&_source=businessentityid,positionno,categoryid,subcategoryid,recordtype,startdate,tilldate,advtypeid,gifname";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "cityid": obj
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }
  public static GetSingleEntity = async (entityid: any): Promise<any> => {
    let elkURl = elkdns+"/businessentity/_search?_source=id,category,categoryid,subcategoryid,subcategory,businessname,address,area,phone,mobile,email,fax,website,recordtype";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "id": entityid
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }
  public static GetRequirementSingleEntity = async (entityid: any): Promise<any> => {
    let elkURl = elkdns+"/requirement/_search?size=10000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "id": entityid
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }
  public static GetSingleEntityFromPG = async (entityid: any): Promise<any> => {
    let elkURl = apiendpoint +"BusinessEntity/"+ entityid
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data);
    })
  }
  public static GetAllBuinessForCity = async (city: any): Promise<any> => {
    let elkURl = elkdns+"/businessentity/_search?size=10000&_source=id,businessname,categoryid,subcategoryid,recordtype,gifname,phone,address,subcity,bazar,landmark,category,eventendtime,eventstarttime";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "advcities": city
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }
  public static GetAllItems = async (obj: any, cat: any): Promise<any> => {
    let elkURl = elkdns+"/businessentity/_search?_source=id,businessname,businessid,phone,address";
    //let elkURl= "http://localhost:2020/api/api/values/PostElkGetBusinessEntities";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "bool": {
              "must": [{
                "match": {
                  "advcities": obj
                }
              },
              { "match": { "subcategoryid": cat } }]
            }
          }
        }
      );

      resolve(results.data.hits.hits);
    })
  }
  public static GetAllEvents = async (obj: any, cat: any): Promise<any> => {
    let elkURl = elkdns+"/businessentity/_search?size=500";
    //let elkURl= "http://localhost:2020/api/api/values/PostElkGetBusinessEntities";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "bool": {
              "must": [{
                "match": {
                  "advcities": obj
                }
              },
              { "match": { "categoryid": cat } },
              { "match": { "recordtype": "Event" } },]
            }
          }
        }
      );

      resolve(results.data.hits.hits);
    })
  }
  public static GetAllEventsELK = async (obj: any): Promise<any> => {
    let elkURl = elkdns+"/businessentity/_search?size=500";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "bool": {
              "must": [{
                "match": {
                  "advcities": obj
                }
              },
              { "match": { "recordtype": "Event" } }]
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }

  public static GetNewsItems = async (): Promise<any> => {
    let elkURl = elkdns+"/news/_search?size=500";
    //let elkURl= "http://localhost:2020/api/api/values/PostElkGetBusinessEntities";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "countryid": 1
            }
          }
        }
      );

      resolve(results.data.hits.hits);
    })
  }

  public static GetNotificationsItems = async (obj: any): Promise<any> => {
    let elkURl = elkdns+"/notifications/_search?size=500";
    //let elkURl= "http://localhost:2020/api/api/values/PostElkGetBusinessEntities";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "cityid": Number(obj)
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }
  public static GetCityInfoFromJHDB = async (obj: any): Promise<any> => {
    let elkURl = elkdns+"/city/_search?size=500";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "title": obj
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }

  public static GetUsefullinks = async (obj: any): Promise<any> => {
    let elkURl = elkdns+"/usefullinks/_search?size=500";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "cityid": Number(obj)
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }
  public static GetCategoryItems = async (): Promise<any> => {
    let elkURl = elkdns+"/category/_search?size=1000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }

  public static GetEventCategoryItems = async (): Promise<any> => {
    let elkURl = elkdns+"/eventscategory/_search?size=500";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }
  public static GetSubCategoryItems = async (): Promise<any> => {
    let elkURl = elkdns+"/subcategory/_search?size=5000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }
  public static GetAllCities = async (): Promise<any> => {
    let elkURl = elkdns+"/city/_search?size=5000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }
  public static GetEducationDetails = async (): Promise<any> => {
    let elkURl = elkdns+"/education/_search?size=50";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }
  public static GetIndustryDetails = async (): Promise<any> => {
    let elkURl = elkdns+"/industry/_search?size=50";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }

  public static GetRequirements = async (obj: any): Promise<any> => {
    let elkURl = elkdns+"/requirement/_search?size=10000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "bool": {
              "must": [{
                "match": {
                  "cityid": obj
                }
              },
              { "match": { "isactive": true } }]
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }

  public static GetITSkills = async (): Promise<any> => {
    let elkURl = elkdns+"/itskills/_search?size=100";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }

  public static GetCityItems = async (obj: any): Promise<any> => {
    let elkURl = elkdns+"/city/_search?size=5000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "countryid": obj
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }
  public static GetCountryItems = async (): Promise<any> => {
    let elkURl = elkdns+"/country/_search?size=100";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }
  public static GetStateItems = async (): Promise<any> => {
    let elkURl = elkdns+"/state/_search?size=1000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }

  public static GetGoogleSearchItems = async (obj: any, pgIndex: any): Promise<any> => {
    let googleAPI = apiendpoint+"GoogleSearchResult?searchKey=" + obj + "&pageIndex=" + pgIndex;
    return new Promise<any>(async (resolve) => {
      let resp: any = [];
      resp = await axios.get(googleAPI);
      console.log(resp.data);
      resolve(resp.data);
    })
  }
  // weather api
  public static GetWeatherInfo = async (obj: any): Promise<any> => {
    let weatherAPI = `https://api.openweathermap.org/data/2.5/weather?q=${obj}&appid=fdab93f6fc85ad8bbc15c49224ec57fd&units=metric`;
    return new Promise<any>(async (resolve) => {
      let resp: any;
      try {
        resp = await axios.get(weatherAPI);
        resolve(resp.data);
      } catch (e) {
        resolve(null);
      }
    })
  }
  //City api
  public static GetUserCityByCoords = async (lat: any, long: any): Promise<any> => {
    let cityAPI = `https://nominatim.openstreetmap.org/reverse?lat=${lat}&lon=${long}&format=json&accept-language=en`;
    return new Promise<any>(async (resolve) => {
      let resp: any;
      try {
        resp = await axios.get(cityAPI);
        resolve(resp.data);
      } catch (e) {
        resolve(null);
      }
    })
  }
  public static GetPollsForCity = async (city: any): Promise<any> => {
    let elkURl = elkdns+"/polls/_search?size=1000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "cityid": city
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }
  public static GetAllPolls = async (): Promise<any> => {
    let elkURl = elkdns+"/polls/_search?size=10000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }
  public static GetAllTrends = async (): Promise<any> => {
    let elkURl = elkdns+"/trends/_search?size=10000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }
  public static GetFranchiseOwnersForCity = async (city: any): Promise<any> => {
    let elkURl = elkdns+"/franchiseowners/_search?size=1000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "cityid": city
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }
  public static GetAdvertisementTypes = async (): Promise<any> => {
    let elkURl = elkdns+"/advertisementtype/_search?size=100";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.get(elkURl);
      resolve(results.data.hits.hits);
    })
  }
  public static GetUsefullinksCatItems = async (obj:any): Promise<any> => {
    let elkURl = elkdns+"/usefullinkscat/_search?size=20";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "countryid": obj
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }
  public static GetUsefullinksItems = async (obj:any): Promise<any> => {
    let elkURl = elkdns+"/usefullinks/_search?size=1000";
    return new Promise<any>(async (resolve) => {
      let results: any = [];
      results = await axios.post(elkURl,
        {
          "query": {
            "match": {
              "countryid": obj
            }
          }
        }
      );
      resolve(results.data.hits.hits);
    })
  }
}
export default HomeUtils;