import React, { Props } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useHistory  } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";


class UserRegistration extends React.Component<any,any,any>{
    
    constructor(props:any) {
        super(props);
        
        this.state = { 
            fields: {},
            errors: {}
        }    
        this.handleChange = this.handleChange.bind(this);
        this.submituserRegistrationForm = this.submituserRegistrationForm.bind(this);    
      }
      

      handleChange=(e:any) =>{
          
        let fields = this.state.fields;
        
        fields[e.target.name] = e.target.value;
        this.setState({
          fields
        });
        
      }
      submituserRegistrationForm=(e:any)=>{
        e.preventDefault();
        if (this.validateForm()) {
            let fields = this.state.fields;
            fields["username"] = "";
            fields["emailid"] = "";
            fields["mobileno"] = "";
            fields["password"] = "";
            this.setState({fields:fields});
            
        }
  
  
      }
      
      validateForm=()=> {
        let fields = this.state.fields;
        let errors = this.state.errors;
        let formIsValid = true;
        if (!fields.username) {
            formIsValid = false;
            errors["username"]="*Please enter your username.";
          }
          if (!fields.email) {
            formIsValid = false;
            errors["email"]="*Please enter your email.";
          }
         let regexEmail= /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;
         if (!regexEmail.test(fields.email)) {
          formIsValid = false;
          errors["email"]="*Please enter valid email.";
         } 
          if (!fields.password) {
            formIsValid = false;
            errors["password"]="*Please enter your password.";
          }
          if (!fields.confirmpassword) {
            formIsValid = false;
            errors["confirmpassword"]="*Please enter your confirm password.";
          }
    
    
        
        this.setState({
            errors: errors
          });
          return formIsValid;
    
    

      }


      // Functions
      CaptchaonChange=(value:any)=>{      
      }
   
    render() {
      return (     
       <div className="container">
    <h2 className="text-center">New User Registration</h2>
    <div className="row">
    <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
          
          </div>
          <div className="col-md-6 col-lg-6 col-sm-12 col-xs-12">
          <div className="form-group">
              <label htmlFor="usr">User Name <span className="text-danger">*</span></label>
              <input type="text"  className="form-control" id="usr" name="username" value={this.state.fields.username} onChange={this.handleChange}  />
              <span className="text-danger">{this.state.errors.username}</span>
            </div>
            <div className="form-group">
              <label htmlFor="pwd">E-mail <span className="text-danger">*</span></label>
              <input type="email" className="form-control" id="pwd" name="email" required/>
              <span className="text-danger">{this.state.errors.email}</span>
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Password <span className="text-danger">*</span></label>
              <input type="password" className="form-control" id="pwd" name="password" required/>
              <span className="text-danger">{this.state.errors.password}</span>
            </div>
            <div className="form-group">
              <label htmlFor="pwd">Confoirm Password <span className="text-danger">*</span></label>
              <input type="password" className="form-control" id="pwd" name="confirmpassword" required/> 
              <span className="text-danger">{this.state.errors.confirmpassword}</span>             
            </div>
            <div className="row">
                <div className="form-group captchaWrapper">
                <ReCAPTCHA
                sitekey="Your client site key" theme="light"
                onChange={this.CaptchaonChange}
                />
            </div>
            
            </div>
            <div className="row">
            <div className="col-md-6">
              <button type="button" className="btn btn-success btn-lg btnSubmit" onClick={this.validateForm}>Submit</button>
            </div>
            <div className="col-md-6">
              <button  className="btn btn-danger btnNewUser btn-lg" >RESET</button>
            </div>
            </div>
          </div>
          <div className="col-md-3 col-lg-3 col-sm-12 col-xs-12">
              
          </div>
          
    </div>
        
          
          
          
          
        
            
       </div>
           
            
        
      
      

       
      )
      
    }
  }
  
  

export default UserRegistration;
