import React, { useEffect } from "react";
import {
  Button,
  Typography,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  DialogActions,
  TextField,
  FormControl,
} from "@material-ui/core";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import { useHistory } from "react-router";
import AxiosApi from "./../../utilities/AxiosApi";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.min.js';
import './../../../src/css/normalize.css';
import Utils from '../utils/homeutils';
import * as _ from 'lodash';
var moment:any = require('moment');


class Poll extends React.Component<{
	successCallback:any,
	country:any,
  cityid:any,
  cityname:any,
  history: any
},{
	pollingData:any,
	ip:any,
	voteButtonStatus:boolean,
	open:boolean,
	votedOption:any,
	loading:boolean
}>{
  constructor(props: any) {
    super(props);
	this.state = {
		pollingData:null,
		ip:"",
		voteButtonStatus:false,
		open:false,
		votedOption:null,
		loading:true
	}
}  
handleClose = () => {
    this.setState({open:false});
	this.setState({voteButtonStatus:true});    
  }
 handleVoting = () => {
	 this.setState({open:false});	 
    fetch("https://api.jaiho.com/Polls/"+this.state.pollingData.id,{
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        id: this.state.pollingData.id,
        title: this.state.pollingData.title,
        option1text: this.state.pollingData.option1text,
        option2text: this.state.pollingData.option2text,
        option3text: this.state.pollingData.option3text,
        option1count: this.state.votedOption == "option1count" ? this.state.pollingData.option1count+1: this.state.pollingData.option1count,
        option2count: this.state.votedOption == "option2count" ? this.state.pollingData.option2count+1: this.state.pollingData.option2count,
        option3count: this.state.votedOption == "option3count" ? this.state.pollingData.option3count+1: this.state.pollingData.option3count,
        isactive: this.state.pollingData.isactive,
        createdby: this.state.pollingData.createdby,
        createddate: this.state.pollingData.createddate,
        modifiedby: this.state.pollingData.modifiedby,
        modifieddate: this.state.pollingData.modifieddate,
      }),
    }).then((response) => {
		let tempPolligObject=this.state.pollingData;
		if(this.state.votedOption=="option1count"){
			tempPolligObject.option1count=this.state.pollingData.option1count+1;
		}
		else if(this.state.votedOption=="option2count"){
			tempPolligObject.option2count=this.state.pollingData.option2count+1;
		}else if(this.state.votedOption=="option3count"){
			tempPolligObject.option3count=this.state.pollingData.option3count+1;
		}
		this.setState({pollingData:tempPolligObject});
        console.log("Update polls " + JSON.stringify(response));
		localStorage.setItem('IPAddress',this.state.ip);
		let pollIDLS=null;
		let pids=[];
		pollIDLS=localStorage.getItem('PID');
		if(pollIDLS!="" && pollIDLS!=null){
			pids=JSON.parse(pollIDLS);
		}
		//let updatedPolls:any=[];
		pids.push(this.state.pollingData.id)
		localStorage.setItem('PID',JSON.stringify(pids));
		//this.setState({open:true});
		 this.props.successCallback(true,"Thank you for voting!");
		 this.setState({voteButtonStatus:true}); 
    }).catch((e) => {
        console.log("Update polls error" + JSON.stringify(e));
    });
  }
  handleAlreadyVoted = () => {
  }
  handlePollChange = (e:any) => {
	  const { name, value } = e.target;
    console.log('name '+name);
	 console.log('val '+value);
	 this.setState({votedOption:value});
  }
   async componentDidUpdate(prevProps:any, _prevState:any) {
	   let that=this;	   
	   if(prevProps.cityid!=this.props.cityid){
		   this.setState({voteButtonStatus:false});
		   AxiosApi.get("Polls").then((data) => {        
        let pollData = data.data;
		let elkPolls=pollData.filter(function (elkPollItem: any) { 					
						return  moment(moment().format('L'))<=moment(elkPollItem.startdate) && elkPollItem.cityid==Number(that.props.cityid) });
		let elkPollData = _.sortBy(elkPolls, (e) => {
						return e.startdate
					});
		if(elkPollData.length>0){
				this.setState({pollingData:elkPollData[0]});
		}else{
			let elkPollsbyEnddate=pollData.filter(function (elkPollItem: any) { 					
						return  moment(moment().format('L'))<=moment(elkPollItem.enddate) && elkPollItem.cityid==Number(that.props.cityid) });
					let elkPollDatabyEnddate = _.sortBy(elkPollsbyEnddate, (e) => {
						return e.enddate
					});
				if(elkPollDatabyEnddate.length>0){
					this.setState({pollingData:elkPollDatabyEnddate[0]});
				}else{
					let elkPollsDefault=pollData.filter(function (elkPollItem: any) { 					
						return  elkPollItem.cityid==Number(that.props.cityid) });
					let elkPollsDefaultSortBy = _.sortBy(elkPollsDefault, (e) => {
						return e.startdate
					});
					if(elkPollsDefaultSortBy.length>0){
						this.setState({pollingData:elkPollsDefaultSortBy[0]});
					}
				}
		}
			
        fetch(`https://geolocation-db.com/json/`)
        .then(res => res.json())
        .then(json => {
            console.log('IP address'+json.IPv4);
            var ipAddress: string = json.IPv4;
            var votedIpAddress = localStorage.getItem('IPAddress');		
            console.log('votedIpAddress '+votedIpAddress);
			let pollsLS=null;
			let pollIDS:any=[];
			pollsLS=localStorage.getItem('PID');
			if(pollsLS!="" && pollsLS!=null){
				pollIDS= JSON.parse(pollsLS);	
			}		   
            if(ipAddress == votedIpAddress && pollIDS.includes(that.state.pollingData.id)){
                console.log('IP address Matched');
                this.setState({voteButtonStatus:true});
            }
			this.setState({ip:json.IPv4});            
        })
      }).catch(() => {});
		
		
  
	   }
   }
async componentDidMount() {	
let that=this;
		/*let elkAllPolls=await Utils.GetPollsForCity(this.props.cityid);	
		console.log(elkAllPolls);
	  let elkPolls=elkAllPolls.filter(function (elkPollItem: any) { 					
						return  moment("2020-11-17")<=moment(elkPollItem.startdate) });
	  let elkPollData = _.sortBy(elkPolls, (e) => {
						return e.startdate
					});*/
	AxiosApi.get("Polls").then((data) => {        
        let pollData = data.data;
		let elkPolls=pollData.filter(function (elkPollItem: any) { 					
						return  moment(moment().format('L'))<=moment(elkPollItem.startdate) && elkPollItem.cityid==Number(that.props.cityid) });
		let elkPollData = _.sortBy(elkPolls, (e) => {
						return e.startdate
					});
		if(elkPollData.length>0){
				this.setState({pollingData:elkPollData[0]});
		}else{
			let elkPollsbyEnddate=pollData.filter(function (elkPollItem: any) { 					
						return  moment(moment().format('L'))<=moment(elkPollItem.enddate) && elkPollItem.cityid==Number(that.props.cityid) });
					let elkPollDatabyEnddate = _.sortBy(elkPollsbyEnddate, (e) => {
						return e.enddate
					});
				if(elkPollDatabyEnddate.length>0){
					this.setState({pollingData:elkPollDatabyEnddate[0]});
				}else{
					let elkPollsDefault=pollData.filter(function (elkPollItem: any) { 					
						return  elkPollItem.cityid==Number(that.props.cityid) });
					let elkPollsDefaultSortBy = _.sortBy(elkPollsDefault, (e) => {
						return e.startdate
					});
					if(elkPollsDefaultSortBy.length>0){
						this.setState({pollingData:elkPollsDefaultSortBy[0]});
					}
				}
		}
						
	   
					try{
          
       fetch(`https://geolocation-db.com/json/`)
        .then(res => res.json())
        .then(json => {
            console.log('IP address'+json.IPv4);
            var ipAddress: string = json.IPv4;
            var votedIpAddress = localStorage.getItem('IPAddress');			
            console.log('votedIpAddress '+votedIpAddress);           
		   let pollsLS=null;
			let pollIDS:any=[];
			pollsLS=localStorage.getItem('PID');
			if(pollsLS!="" && pollsLS!=null){
				
				pollIDS= JSON.parse(pollsLS);	
			}
            if(ipAddress!="" && that.state.pollingData!=null && pollIDS.includes(that.state.pollingData.id)){
                console.log('IP address Matched');
                this.setState({voteButtonStatus:true});
            }
			this.setState({ip:json.IPv4,loading:false});
          
        })
          }catch(e){}
		
  
      }).catch(() => {});
		
		

}
  render() { 
let {voteButtonStatus,open,ip,votedOption,pollingData,loading}=this.state;
const {history,cityid,cityname,country}=this.props;  
    return (
	<div className="widget widget-color-8 jh-block-polling">
      <div className="widget-header">
      <span className="widget-caption">JaiHo Polling</span>
        <i className="widget-icon fa fa-hand-o-up"/>
      </div>
      <Dialog
        open={open}
        onClose={this.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Thank you for voting..!</DialogTitle>

        <DialogActions>
          <Button onClick={this.handleClose} color="primary" autoFocus>
            Ok
          </Button>
        </DialogActions>
      </Dialog>
      {!loading && pollingData!=null && (<div className="widget-body">
        <div className="widget-body-polling-inner">
          <p className="jh-polling-qn">{pollingData.title}</p>
          <ul className="jh-polling-options">
            <li className="jh-polling-options-list">
              {voteButtonStatus == true ? 
          <div>
            <Typography>{pollingData.option1text + ' ('+pollingData.option1count + ' votes)'}</Typography>
            <div className="progress">
		          <div className="progress-bar progress-bar-striped"  style={{width:`${Math.ceil((pollingData.option1count / (pollingData.option1count+pollingData.option2count+pollingData.option3count)) * 100)}%`}}>{Math.ceil((pollingData.option1count / (pollingData.option1count+pollingData.option2count+pollingData.option3count)) * 100) + '%'}</div>
		        </div>
          </div>
            :
            <label>
              <input type="radio" name="polloption" value="option1count" id="rdoPoll1" onChange={this.handlePollChange} style={{margin:8}}/>
              {pollingData.option1text}
            </label>}
          </li>
          <li className="jh-polling-options-list">
          {voteButtonStatus == true ? 
          <div>
              <Typography>{pollingData.option2text + ' ('+pollingData.option2count + ' votes)'}</Typography>
           <div className="progress">
		   <div className="progress-bar progress-bar-striped bg-warning"  style={{width:`${Math.trunc((pollingData.option2count / (pollingData.option1count+pollingData.option2count+pollingData.option3count)) * 100)}%`}}>{Math.trunc((pollingData.option2count / (pollingData.option1count+pollingData.option2count+pollingData.option3count)) * 100) + '%'}</div>
		  </div>
          </div>
            :
            <label>
              <input type="radio" name="polloption" id="rdoPoll2" value="option2count" onChange={this.handlePollChange} style={{margin:8}}/>
              {pollingData.option2text}
            </label>}
          </li>
          <li className="jh-polling-options-list">
          {voteButtonStatus == true ? 
          <div>
              <Typography>{pollingData.option3text + ' ('+pollingData.option3count + ' votes)'}</Typography>
          <div className="progress">
		   <div className="progress-bar progress-bar-striped bg-success"  style={{width:`${Math.trunc((pollingData.option3count / (pollingData.option1count+pollingData.option2count+pollingData.option3count)) * 100)}%`}}>{Math.trunc((pollingData.option3count / (pollingData.option1count+pollingData.option2count+pollingData.option3count)) * 100) + '%'}</div>
		  </div>
          </div>
            :
            <label>
              <input type="radio" name="polloption" id="rdoPoll3" value="option3count" onChange={this.handlePollChange} style={{margin:8}}/>
              {pollingData.option3text}
            </label>
            }
            
          </li>
          <li className="jh-polling-options-list">              
		  {!voteButtonStatus && <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                marginTop:5,
              }}
            >
              <Button
                onClick={this.handleVoting}
                variant="outlined"
                color="inherit"
                style={{
                  display: "flex",
                  width: "100%",
                  color: "#fff",
                  background:"#00B16A"
                }}
              >
               Vote
              </Button>
		  </div>}
            <div
              style={{
                display: "flex",
                alignContent: "center",
                justifyContent: "center",
                marginTop: 5,
              }}
            >
              <Button
                onClick={() => history.push(`/polling/${country}/${cityname}/${cityid}`)}
                variant="outlined"
                color="inherit"
                endIcon={
                  <ArrowForwardIosIcon
                    style={{ marginBottom: 4, fontSize: "medium" }}
                  />
                }
                style={{
                  display: "flex",
                  width: "100%",
                  color: "#000",
                  borderColor: "#fff",
                  fontSize: 12,
                  //background: "#00B16A",
                }}
              >
                View Previous Polls
              </Button>
            </div>
          </li>
        </ul>
        </div>
      </div>)
	  }
    </div>

	)
	
  }
}


export default Poll;
